<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.792" height="18.555" viewBox="0 0 19.792 18.555">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_17216" data-name="Rectangle 17216" width="19.792" height="18.555" fill="none" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_45673" data-name="Group 45673" clip-path="url(#clip-path)"> -->
    <g id="Group_45673" data-name="Group 45673">
      <path
        id="Path_29197"
        data-name="Path 29197"
        d="M19.792,1.237A1.237,1.237,0,0,0,18.555,0H1.237A1.237,1.237,0,0,0,0,1.237V4.948H19.792Z"
        fill="#0069c1"
      />
      <path
        id="Path_29198"
        data-name="Path 29198"
        d="M0,59.36V71.4a1.566,1.566,0,0,0,1.566,1.566H18.226A1.566,1.566,0,0,0,19.792,71.4V59.36ZM6.185,71.5a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V69.173a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227V71.5Zm0-4.175a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V65a.226.226,0,0,1,.226-.227h4.5A.227.227,0,0,1,6.184,65v2.329Zm0-4.174a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V60.824a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227v2.329ZM12.37,71.5a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V69.173a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227V71.5Zm0-4.175a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V65a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227v2.329Zm0-4.174a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V60.824a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227v2.329Zm6.185,8.35a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V69.173a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227V71.5Zm0-4.175a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V65a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227v2.329Zm0-4.174a.226.226,0,0,1-.225.228h-4.5a.227.227,0,0,1-.227-.227h0V60.824a.226.226,0,0,1,.226-.227h4.5a.227.227,0,0,1,.227.227v2.329Z"
        transform="translate(0 -54.412)"
        fill="#a2abb7"
      />
    </g>
  </svg>
</template>
