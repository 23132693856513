<template>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- <g clip-path="url(#clip0_2691_68046)"> -->
    <g>
      <path
        d="M111.75 113.72C113.49 113.72 114.9 112.31 114.9 110.57C114.9 108.83 113.49 107.42 111.75 107.42C110.01 107.42 108.6 108.83 108.6 110.57C108.6 112.31 110.01 113.72 111.75 113.72Z"
        fill="#003366"
      />
      <path d="M92.0507 35.7104V22.4004H89.9707V35.7104H92.0507Z" fill="#007FFF" />
      <path d="M84.3594 30.0898H97.6694V28.0098H84.3594V30.0898Z" fill="#007FFF" />
      <path d="M3.72 117.511L13.13 108.101L11.66 106.631L2.25 116.041L3.72 117.511Z" fill="#007FFF" />
      <path d="M2.25 108.101L11.66 117.511L13.13 116.041L3.72 106.631L2.25 108.101Z" fill="#007FFF" />
      <path
        d="M25.4 110C21.33 110 17.35 108.4 14.48 105.62C11.59 102.82 10 99.1205 10 95.1705V72.4605C10.03 72.0105 10.23 71.5805 10.58 71.2505C10.96 70.9005 11.48 70.6905 12.02 70.6905C12.56 70.6905 13.08 70.8905 13.46 71.2505C13.81 71.5805 14.01 72.0005 14.04 72.4605V95.1705C14.04 98.1105 15.27 100.98 17.41 103.05C19.55 105.12 22.39 106.27 25.4 106.27H41.84V78.7105H17.82C17.3 78.6805 16.8 78.4705 16.44 78.1105C16.08 77.7505 15.91 77.3205 15.91 76.8505C15.91 76.3805 16.1 75.9405 16.44 75.5905C16.8 75.2305 17.3 75.0205 17.82 74.9905H41.84V47.4105H14.03V55.5505C14.01 56.0005 13.8 56.4305 13.46 56.7605C13.08 57.1105 12.56 57.3205 12.02 57.3205C11.48 57.3205 10.96 57.1205 10.58 56.7605C10.23 56.4305 10.03 56.0105 10 55.5505V26.5505C10 22.6105 11.6 18.9005 14.48 16.1105C17.35 13.3305 21.33 11.7305 25.4 11.7305H94.6C98.67 11.7305 102.65 13.3305 105.51 16.1105C108.39 18.9105 109.99 22.6105 109.99 26.5505V95.1505C109.99 99.0905 108.39 102.79 105.51 105.59C102.64 108.37 98.66 109.97 94.59 109.97H25.4V110ZM78.16 106.29H94.6C97.62 106.29 100.46 105.14 102.6 103.07C104.73 101 105.96 98.1305 105.97 95.1905V78.7305H78.17V106.29H78.16ZM45.87 106.29H74.15V78.7305H45.87V106.29ZM78.16 75.0005H105.96V47.4305H78.16V75.0005ZM45.86 75.0005H74.13V47.4305H45.87V75.0005H45.86ZM25.4 15.4705C22.38 15.4705 19.54 16.6205 17.4 18.6905C15.26 20.7605 14.04 23.6305 14.03 26.5705V43.7105H105.97V26.5805C105.97 23.6405 104.74 20.7705 102.6 18.7005C100.46 16.6305 97.62 15.4805 94.61 15.4805H25.4V15.4705Z"
        fill="#003366"
      />
      <path d="M71.9695 49.2402H48.0195V73.1902H71.9695V49.2402Z" fill="#007FFF" />
      <path d="M104.04 49.2402H80.0898V73.1902H104.04V49.2402Z" fill="#007FFF" />
      <path d="M39.8504 49.2402H15.9004V73.1902H39.8504V49.2402Z" fill="#007FFF" />
      <path d="M25.8804 17.0801H78.1604V41.0301H15.9004V27.0601C15.9004 21.5501 20.3704 17.0801 25.8804 17.0801Z" fill="#003366" />
      <path d="M71.9695 80.5293H48.0195V104.479H71.9695V80.5293Z" fill="#007FFF" />
      <path d="M80.0898 80.5293H104.04V94.4993C104.04 100.009 99.5698 104.479 94.0598 104.479H80.0898V80.5293Z" fill="#007FFF" />
      <path d="M15.9004 80.5293H39.8504V104.479H25.8804C20.3704 104.479 15.9004 100.009 15.9004 94.4993V80.5293Z" fill="#007FFF" />
      <path
        d="M10.6594 65.7497C11.4794 66.4997 12.7594 66.4297 13.5094 65.6097C14.2594 64.7897 14.1894 63.5097 13.3694 62.7597C12.5494 62.0097 11.2694 62.0797 10.5194 62.8997C9.76943 63.7197 9.83943 64.9997 10.6594 65.7497Z"
        fill="#003366"
      />
      <path
        d="M114.079 5.92913C114.759 6.54913 115.809 6.48913 116.429 5.80913C117.049 5.12913 116.989 4.07913 116.309 3.45913C115.629 2.83913 114.579 2.89913 113.959 3.57913C113.339 4.25913 113.399 5.30913 114.079 5.92913Z"
        fill="#003366"
      />
      <path
        d="M104.601 13.13C106.411 14.77 109.211 14.63 110.851 12.82C112.491 11.01 112.351 8.21003 110.541 6.57003C108.731 4.93003 105.931 5.07003 104.291 6.88003C102.651 8.69003 102.791 11.49 104.601 13.13Z"
        fill="#003366"
      />
    </g>
    <defs>
      <!-- <clipPath id="clip0_2691_68046">
        <rect width="120" height="120" fill="white" />
      </clipPath> -->
    </defs>
  </svg>
</template>
