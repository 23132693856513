import { useModalStore } from '@/store/modalStore';
import { useSettingStore } from '@/store/settingStore';
import authService from '@services/authentication';
import { storeToRefs } from 'pinia';
import DEFAULT_ERROR_MESSAGES from '../utils';

export interface ValidationModalPayload {
  title: string;
  description: string;
  type: 'warning' | 'warning-title-danger' | 'success' | 'error' | 'danger';
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  onClose?: () => Promise<void> | void;
  confirmLabel?: string;
  confirmBtn_Disabled?: boolean;
  cancelBtn_Disabled?: boolean;
  autoClose?: boolean;
}

export default function useValidationModal() {
  const store = useModalStore();
  const settingStore = useSettingStore();
  const { currentLocale } = storeToRefs(settingStore);

  const logout = () => {
    authService.fetchLogout();
  };

  const closeValidationModal = (callback: () => void) => {
    callback();
    return store.CLOSE_VALIDATION_MODAL();
  };

  const openWarningModal = (
    title               = '',
    description         = '',
    onConfirm           = () => {
    },
    onCancel            = () => {
    },
    onClose             = () => {
    },
    confirmLabel        = '',
    confirmBtn_Disabled = false,
    cancelBtn_Disabled  = false,
  ) => {
    const payload = {
      title,
      description,
      type: 'warning',
      onConfirm,
      onCancel,
      onClose,
      confirmLabel,
      confirmBtn_Disabled,
      cancelBtn_Disabled,
    };
    store.OPEN_VALIDATION_MODAL(payload);
  };

  const openWarningTitleDangerModal = (
    title               = '',
    description         = '',
    onConfirm           = () => {
    },
    onCancel            = () => {
    },
    onClose             = () => {
    },
    confirmLabel        = '',
    confirmBtn_Disabled = false,
    cancelBtn_Disabled  = false,
  ) => {
    const payload = {
      title,
      description,
      type: 'warning-title-danger',
      onConfirm,
      onCancel,
      onClose,
      confirmLabel,
      confirmBtn_Disabled,
      cancelBtn_Disabled,
    };
    store.OPEN_VALIDATION_MODAL(payload);
  };

  const openSuccessModal = (
    title               = '',
    description         = '',
    confirmLabel        = '',
    onConfirm           = () => {
    },
    onClose             = () => {
    },
    confirmBtn_Disabled = false,
    cancelBtn_Disabled  = false,
    autoClose           = false,
  ) => {
    const payload = {
      title,
      description,
      confirmLabel,
      type: 'success',
      onConfirm,
      onCancel: () => {
      },
      onClose,
      confirmBtn_Disabled,
      cancelBtn_Disabled,
    };
    store.OPEN_VALIDATION_MODAL(payload);

    if (autoClose) {
      setTimeout(() => {
        store.CLOSE_VALIDATION_MODAL();
      }, 2000);
    }
  };

  const openErrorModal = (title = '', description = '', confirmLabel = '', onConfirm = () => {
  }, onClose                    = () => {
  }) => {
    const payload = {
      title,
      description,
      confirmLabel,
      type: 'error',
      onConfirm,
      onCancel: () => {
      },
      onClose,
      confirmBtn_Disabled: false,
      cancelBtn_Disabled: false,
    };

    store.OPEN_VALIDATION_MODAL(payload);
  };

  const openDefaultErrorModal = (error: any, onConfirm?: () => void, onClose?: () => void) => {
    let title =
          currentLocale.value == 'th'
            ? DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR_MESSAGES['th'].title
            : DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR_MESSAGES['en'].title;
    let detail =
          currentLocale.value == 'th'
            ? DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR_MESSAGES['th'].message
            : DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR_MESSAGES['en'].message;

    if (error?.data.error.locale) {
      const errorResponse = error.data.error;

      if (errorResponse?.code !== 1000404 || errorResponse?.code !== 1000400) {
        const localeTitle = currentLocale.value == 'th' ? errorResponse?.locale['th-th']?.title : errorResponse?.locale['en-us']?.title;

        const localeDetail = currentLocale.value == 'th' ? errorResponse?.locale['th-th']?.message : errorResponse?.locale['en-us']?.message;

        title = localeTitle;
        detail = localeDetail;
      }
    } else if (error?.data.error.message) {
      title = error?.data.error.message;
      detail = '';
    } else if (error?.data.error.system) {
      title = error?.data.error.system;
      detail = '';
    }

    openErrorModal(title, detail, undefined, error.status === 401 ? () => logout() : onConfirm, error.status === 401 ? () => logout() : onClose);
  };

  const openDangerModal = (
    title               = '',
    description         = '',
    onConfirm           = () => {
    },
    onCancel            = () => {
    },
    onClose             = () => {
    },
    confirmLabel        = '',
    confirmBtn_Disabled = false,
    cancelBtn_Disabled  = false,
  ) => {
    const payload = {
      title,
      description,
      type: 'danger',
      onConfirm,
      onCancel,
      onClose,
      confirmLabel,
      confirmBtn_Disabled,
      cancelBtn_Disabled,
    };
    store.OPEN_VALIDATION_MODAL(payload);
  };

  return {
    openWarningModal,
    openWarningTitleDangerModal,
    openSuccessModal,
    openErrorModal,
    openDefaultErrorModal,
    openDangerModal,
    closeValidationModal,
  };
}
