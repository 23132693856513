<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.179" height="11.689" viewBox="0 0 20.179 11.689">
    <defs>
      <!-- <clipPath id="clip-path-102">
        <rect id="Rectangle_14663" data-name="Rectangle 14663" width="20.179" height="11.688" />
      </clipPath> -->
    </defs>
    <g id="Group_33788" data-name="Group 33788" transform="translate(0 0)">
      <!-- <g id="Group_33784" data-name="Group 33784" transform="translate(0 0)" clip-path="url(#clip-path)"> -->
      <g id="Group_33784" data-name="Group 33784" transform="translate(0 0)">
        <path
          id="Path_22591"
          data-name="Path 22591"
          d="M3.924,11.689A3.9,3.9,0,0,1,0,7.971,3.817,3.817,0,0,1,2.673,4.223,5.847,5.847,0,0,1,13.5,3.207,4.269,4.269,0,0,1,17.8,5.087,3.352,3.352,0,0,1,20.173,8.5a3.424,3.424,0,0,1-3.463,3.186ZM3.816,5.662A2.206,2.206,0,0,0,1.615,7.9a2.261,2.261,0,0,0,2.293,2.171H16.731a1.807,1.807,0,0,0,1.826-1.622,1.76,1.76,0,0,0-1.67-1.9A.252.252,0,0,1,16.67,6.4a2.652,2.652,0,0,0-3.861-1.228.256.256,0,0,1-.131.038.252.252,0,0,1-.242-.2,4.229,4.229,0,0,0-8.356.438.24.24,0,0,1-.239.217H3.816"
          transform="translate(0.001 -0.001)"
        />
      </g>
    </g>
  </svg>
</template>
