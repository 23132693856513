<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18.739" height="19" viewBox="0 0 18.739 20.679">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_17618" data-name="Rectangle 17618" width="18.739" height="20.679" />
      </clipPath> -->
    </defs>
    <g id="Group_47874" data-name="Group 47874" transform="translate(0 0)">
      <!-- <g id="Group_47873" data-name="Group 47873" transform="translate(0 0)" clip-path="url(#clip-path)"> -->
      <g id="Group_47873" data-name="Group 47873" transform="translate(0 0)">
        <path
          id="Path_29232"
          data-name="Path 29232"
          d="M17.033,1.94H14.512V.87a.87.87,0,1,0-1.74,0V1.94H6.615V.87a.87.87,0,0,0-1.74,0V1.94H1.706A1.705,1.705,0,0,0,0,3.645V18.973a1.705,1.705,0,0,0,1.706,1.706H12.453c.132,0,.261-.011.39-.019a6.332,6.332,0,0,0,5.9-6.266V3.645A1.706,1.706,0,0,0,17.033,1.94m-2.22,16.739a9.445,9.445,0,0,0-.129-2.641,14.389,14.389,0,0,0,2.472-.3,4.915,4.915,0,0,1-2.343,2.945m2.535-4.695a12.882,12.882,0,0,1-2.932.389,4.591,4.591,0,0,1-.657-.036.814.814,0,0,0-.153-.014.835.835,0,0,0-.8,1.083,7.8,7.8,0,0,1,.235,3.843,4.871,4.871,0,0,1-.591.039H1.706a.315.315,0,0,1-.315-.315V8.677H17.348ZM1.391,7V3.645a.315.315,0,0,1,.315-.315H4.874v.781a.87.87,0,1,0,1.74,0V3.33h6.157v.781a.87.87,0,1,0,1.74,0V3.33h2.522a.315.315,0,0,1,.315.315V7Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_29233"
          data-name="Path 29233"
          d="M11.834,31.856h1.52a.551.551,0,0,0,.55-.551V29.786a.55.55,0,0,0-.55-.551h-1.52a.551.551,0,0,0-.551.551v1.519a.551.551,0,0,0,.551.551"
          transform="translate(-7.361 -19.072)"
        />
        <path
          id="Path_29234"
          data-name="Path 29234"
          d="M11.834,44.081h1.52a.55.55,0,0,0,.55-.551V42.01a.551.551,0,0,0-.55-.551h-1.52a.551.551,0,0,0-.551.551V43.53a.551.551,0,0,0,.551.551"
          transform="translate(-7.361 -27.046)"
        />
        <path
          id="Path_29235"
          data-name="Path 29235"
          d="M24.053,44.081h1.52a.551.551,0,0,0,.551-.551V42.01a.551.551,0,0,0-.551-.551h-1.52a.551.551,0,0,0-.551.551V43.53a.551.551,0,0,0,.551.551"
          transform="translate(-15.332 -27.046)"
        />
        <path
          id="Path_29236"
          data-name="Path 29236"
          d="M24.053,31.856h1.52a.551.551,0,0,0,.551-.551V29.786a.551.551,0,0,0-.551-.551h-1.52a.551.551,0,0,0-.551.551v1.519a.551.551,0,0,0,.551.551"
          transform="translate(-15.332 -19.072)"
        />
        <path
          id="Path_29237"
          data-name="Path 29237"
          d="M36.272,31.856h1.52a.551.551,0,0,0,.551-.551V29.786a.551.551,0,0,0-.551-.551h-1.52a.551.551,0,0,0-.551.551v1.519a.551.551,0,0,0,.551.551"
          transform="translate(-23.303 -19.072)"
        />
      </g>
    </g>
  </svg>
</template>
