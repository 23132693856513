<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44" height="44" viewBox="0 0 44 44">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect
          id="Rectangle_17887"
          data-name="Rectangle 17887"
          width="44"
          height="44"
          rx="5"
          transform="translate(-5027.428 -7912.161)"
          fill="none"
          stroke="#ededed"
          stroke-width="1"
        />
      </clipPath> -->
      <!-- <clipPath id="clip-path-2">
        <rect id="Rectangle_17873" data-name="Rectangle 17873" width="45.097" height="44.673" fill="none" />
      </clipPath> -->
    </defs>
    <!-- <g id="Mask_Group_143" data-name="Mask Group 143" transform="translate(5027.428 7912.161)" clip-path="url(#clip-path)"> -->
    <g id="Mask_Group_143" data-name="Mask Group 143" transform="translate(5027.428 7912.161)">
      <g id="Group_46040" data-name="Group 46040" transform="translate(29.797 161.494)">
        <!-- <g id="Group_46038" data-name="Group 46038" transform="translate(-5057.773 -8073.991)" clip-path="url(#clip-path-2)"> -->
        <g id="Group_46038" data-name="Group 46038" transform="translate(-5057.773 -8073.991)">
          <rect id="Rectangle_17713" data-name="Rectangle 17713" width="5.563" height="5.563" transform="translate(6.154 0.167)" fill="#ddd" />
          <rect id="Rectangle_17714" data-name="Rectangle 17714" width="5.563" height="5.563" transform="translate(0.591 5.73)" fill="#ddd" />
          <rect id="Rectangle_17715" data-name="Rectangle 17715" width="5.563" height="5.563" transform="translate(17.709 0.167)" fill="#ddd" />
          <rect id="Rectangle_17716" data-name="Rectangle 17716" width="5.991" height="5.563" transform="translate(11.718 5.73)" fill="#ddd" />
          <rect id="Rectangle_17717" data-name="Rectangle 17717" width="5.563" height="5.563" transform="translate(28.835 0.167)" fill="#ddd" />
          <rect id="Rectangle_17718" data-name="Rectangle 17718" width="5.563" height="5.563" transform="translate(23.272 5.73)" fill="#ddd" />
          <rect id="Rectangle_17719" data-name="Rectangle 17719" width="5.563" height="5.563" transform="translate(39.962 0.167)" fill="#ddd" />
          <rect id="Rectangle_17720" data-name="Rectangle 17720" width="5.563" height="5.563" transform="translate(34.398 5.73)" fill="#ddd" />
          <rect id="Rectangle_17721" data-name="Rectangle 17721" width="5.563" height="5.563" transform="translate(51.516 0.167)" fill="#ddd" />
          <rect id="Rectangle_17722" data-name="Rectangle 17722" width="5.991" height="5.563" transform="translate(45.525 5.73)" fill="#ddd" />
          <rect id="Rectangle_17723" data-name="Rectangle 17723" width="5.563" height="5.563" transform="translate(62.643 0.167)" fill="#ddd" />
          <rect id="Rectangle_17724" data-name="Rectangle 17724" width="5.563" height="5.563" transform="translate(57.08 5.73)" fill="#ddd" />
          <rect id="Rectangle_17725" data-name="Rectangle 17725" width="5.991" height="5.563" transform="translate(73.769 0.167)" fill="#ddd" />
          <rect id="Rectangle_17726" data-name="Rectangle 17726" width="5.563" height="5.563" transform="translate(68.206 5.73)" fill="#ddd" />
          <rect id="Rectangle_17727" data-name="Rectangle 17727" width="5.563" height="5.563" transform="translate(85.324 0.167)" fill="#ddd" />
          <rect id="Rectangle_17728" data-name="Rectangle 17728" width="5.563" height="5.563" transform="translate(79.761 5.73)" fill="#ddd" />
          <rect id="Rectangle_17729" data-name="Rectangle 17729" width="5.563" height="5.563" transform="translate(96.45 0.167)" fill="#ddd" />
          <rect id="Rectangle_17730" data-name="Rectangle 17730" width="5.563" height="5.563" transform="translate(90.887 5.73)" fill="#ddd" />
          <rect id="Rectangle_17731" data-name="Rectangle 17731" width="5.991" height="5.563" transform="translate(107.577 0.167)" fill="#ddd" />
          <rect id="Rectangle_17732" data-name="Rectangle 17732" width="5.563" height="5.563" transform="translate(102.014 5.73)" fill="#ddd" />
          <rect id="Rectangle_17733" data-name="Rectangle 17733" width="5.563" height="5.563" transform="translate(0.591 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17734" data-name="Rectangle 17734" width="5.563" height="5.563" transform="translate(6.154 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17735" data-name="Rectangle 17735" width="5.991" height="5.563" transform="translate(11.718 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17736" data-name="Rectangle 17736" width="5.563" height="5.563" transform="translate(17.709 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17737" data-name="Rectangle 17737" width="5.563" height="5.563" transform="translate(23.272 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17738" data-name="Rectangle 17738" width="5.563" height="5.563" transform="translate(28.835 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17739" data-name="Rectangle 17739" width="5.563" height="5.563" transform="translate(34.398 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17740" data-name="Rectangle 17740" width="5.563" height="5.563" transform="translate(39.962 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17741" data-name="Rectangle 17741" width="5.991" height="5.563" transform="translate(45.525 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17742" data-name="Rectangle 17742" width="5.563" height="5.563" transform="translate(51.516 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17743" data-name="Rectangle 17743" width="5.563" height="5.563" transform="translate(57.08 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17744" data-name="Rectangle 17744" width="5.563" height="5.563" transform="translate(62.643 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17745" data-name="Rectangle 17745" width="5.563" height="5.563" transform="translate(68.206 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17746" data-name="Rectangle 17746" width="5.991" height="5.563" transform="translate(73.769 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17747" data-name="Rectangle 17747" width="5.563" height="5.563" transform="translate(79.761 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17748" data-name="Rectangle 17748" width="5.563" height="5.563" transform="translate(85.324 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17749" data-name="Rectangle 17749" width="5.563" height="5.563" transform="translate(90.887 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17750" data-name="Rectangle 17750" width="5.563" height="5.563" transform="translate(96.45 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17751" data-name="Rectangle 17751" width="5.563" height="5.563" transform="translate(102.014 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17752" data-name="Rectangle 17752" width="5.991" height="5.563" transform="translate(107.577 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17753" data-name="Rectangle 17753" width="5.563" height="5.563" transform="translate(6.154 22.42)" fill="#ddd" />
          <rect id="Rectangle_17754" data-name="Rectangle 17754" width="5.563" height="5.563" transform="translate(0.591 27.983)" fill="#ddd" />
          <rect id="Rectangle_17755" data-name="Rectangle 17755" width="5.563" height="5.563" transform="translate(17.709 22.42)" fill="#ddd" />
          <rect id="Rectangle_17756" data-name="Rectangle 17756" width="5.991" height="5.563" transform="translate(11.718 27.983)" fill="#ddd" />
          <rect id="Rectangle_17757" data-name="Rectangle 17757" width="5.563" height="5.563" transform="translate(28.835 22.42)" fill="#ddd" />
          <rect id="Rectangle_17758" data-name="Rectangle 17758" width="5.563" height="5.563" transform="translate(23.272 27.983)" fill="#ddd" />
          <rect id="Rectangle_17759" data-name="Rectangle 17759" width="5.563" height="5.563" transform="translate(39.962 22.42)" fill="#ddd" />
          <rect id="Rectangle_17760" data-name="Rectangle 17760" width="5.563" height="5.563" transform="translate(34.398 27.983)" fill="#ddd" />
          <rect id="Rectangle_17761" data-name="Rectangle 17761" width="5.563" height="5.563" transform="translate(51.516 22.42)" fill="#ddd" />
          <rect id="Rectangle_17762" data-name="Rectangle 17762" width="5.991" height="5.563" transform="translate(45.525 27.983)" fill="#ddd" />
          <rect id="Rectangle_17763" data-name="Rectangle 17763" width="5.563" height="5.563" transform="translate(62.643 22.42)" fill="#ddd" />
          <rect id="Rectangle_17764" data-name="Rectangle 17764" width="5.563" height="5.563" transform="translate(57.08 27.983)" fill="#ddd" />
          <rect id="Rectangle_17765" data-name="Rectangle 17765" width="5.991" height="5.563" transform="translate(73.769 22.42)" fill="#ddd" />
          <rect id="Rectangle_17766" data-name="Rectangle 17766" width="5.563" height="5.563" transform="translate(68.206 27.983)" fill="#ddd" />
          <rect id="Rectangle_17767" data-name="Rectangle 17767" width="5.563" height="5.563" transform="translate(85.324 22.42)" fill="#ddd" />
          <rect id="Rectangle_17768" data-name="Rectangle 17768" width="5.563" height="5.563" transform="translate(79.761 27.983)" fill="#ddd" />
          <rect id="Rectangle_17769" data-name="Rectangle 17769" width="5.563" height="5.563" transform="translate(96.45 22.42)" fill="#ddd" />
          <rect id="Rectangle_17770" data-name="Rectangle 17770" width="5.563" height="5.563" transform="translate(90.887 27.983)" fill="#ddd" />
          <rect id="Rectangle_17771" data-name="Rectangle 17771" width="5.991" height="5.563" transform="translate(107.577 22.42)" fill="#ddd" />
          <rect id="Rectangle_17772" data-name="Rectangle 17772" width="5.563" height="5.563" transform="translate(102.014 27.983)" fill="#ddd" />
          <rect id="Rectangle_17773" data-name="Rectangle 17773" width="5.563" height="5.563" transform="translate(0.591 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17774" data-name="Rectangle 17774" width="5.563" height="5.563" transform="translate(6.154 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17775" data-name="Rectangle 17775" width="5.991" height="5.563" transform="translate(11.718 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17776" data-name="Rectangle 17776" width="5.563" height="5.563" transform="translate(17.709 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17777" data-name="Rectangle 17777" width="5.563" height="5.563" transform="translate(23.272 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17778" data-name="Rectangle 17778" width="5.563" height="5.563" transform="translate(28.835 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17779" data-name="Rectangle 17779" width="5.563" height="5.563" transform="translate(34.398 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17780" data-name="Rectangle 17780" width="5.563" height="5.563" transform="translate(39.962 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17781" data-name="Rectangle 17781" width="5.991" height="5.563" transform="translate(45.525 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17782" data-name="Rectangle 17782" width="5.563" height="5.563" transform="translate(51.516 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17783" data-name="Rectangle 17783" width="5.563" height="5.563" transform="translate(57.08 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17784" data-name="Rectangle 17784" width="5.563" height="5.563" transform="translate(62.643 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17785" data-name="Rectangle 17785" width="5.563" height="5.563" transform="translate(68.206 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17786" data-name="Rectangle 17786" width="5.991" height="5.563" transform="translate(73.769 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17787" data-name="Rectangle 17787" width="5.563" height="5.563" transform="translate(79.761 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17788" data-name="Rectangle 17788" width="5.563" height="5.563" transform="translate(85.324 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17789" data-name="Rectangle 17789" width="5.563" height="5.563" transform="translate(90.887 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17790" data-name="Rectangle 17790" width="5.563" height="5.563" transform="translate(96.45 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17791" data-name="Rectangle 17791" width="5.563" height="5.563" transform="translate(102.014 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17792" data-name="Rectangle 17792" width="5.991" height="5.563" transform="translate(107.577 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17793" data-name="Rectangle 17793" width="5.563" height="5.563" transform="translate(6.154 11.293)" fill="#ddd" />
          <rect id="Rectangle_17794" data-name="Rectangle 17794" width="5.563" height="5.563" transform="translate(0.591 16.857)" fill="#ddd" />
          <rect id="Rectangle_17795" data-name="Rectangle 17795" width="5.563" height="5.563" transform="translate(17.709 11.293)" fill="#ddd" />
          <rect id="Rectangle_17796" data-name="Rectangle 17796" width="5.991" height="5.563" transform="translate(11.718 16.857)" fill="#ddd" />
          <rect id="Rectangle_17797" data-name="Rectangle 17797" width="5.563" height="5.563" transform="translate(28.835 11.293)" fill="#ddd" />
          <rect id="Rectangle_17798" data-name="Rectangle 17798" width="5.563" height="5.563" transform="translate(23.272 16.857)" fill="#ddd" />
          <rect id="Rectangle_17799" data-name="Rectangle 17799" width="5.563" height="5.563" transform="translate(39.962 11.293)" fill="#ddd" />
          <rect id="Rectangle_17800" data-name="Rectangle 17800" width="5.563" height="5.563" transform="translate(34.398 16.857)" fill="#ddd" />
          <rect id="Rectangle_17801" data-name="Rectangle 17801" width="5.563" height="5.563" transform="translate(51.516 11.293)" fill="#ddd" />
          <rect id="Rectangle_17802" data-name="Rectangle 17802" width="5.991" height="5.563" transform="translate(45.525 16.857)" fill="#ddd" />
          <rect id="Rectangle_17803" data-name="Rectangle 17803" width="5.563" height="5.563" transform="translate(62.643 11.293)" fill="#ddd" />
          <rect id="Rectangle_17804" data-name="Rectangle 17804" width="5.563" height="5.563" transform="translate(57.08 16.857)" fill="#ddd" />
          <rect id="Rectangle_17805" data-name="Rectangle 17805" width="5.991" height="5.563" transform="translate(73.769 11.293)" fill="#ddd" />
          <rect id="Rectangle_17806" data-name="Rectangle 17806" width="5.563" height="5.563" transform="translate(68.206 16.857)" fill="#ddd" />
          <rect id="Rectangle_17807" data-name="Rectangle 17807" width="5.563" height="5.563" transform="translate(85.324 11.293)" fill="#ddd" />
          <rect id="Rectangle_17808" data-name="Rectangle 17808" width="5.563" height="5.563" transform="translate(79.761 16.857)" fill="#ddd" />
          <rect id="Rectangle_17809" data-name="Rectangle 17809" width="5.563" height="5.563" transform="translate(96.45 11.293)" fill="#ddd" />
          <rect id="Rectangle_17810" data-name="Rectangle 17810" width="5.563" height="5.563" transform="translate(90.887 16.857)" fill="#ddd" />
          <rect id="Rectangle_17811" data-name="Rectangle 17811" width="5.991" height="5.563" transform="translate(107.577 11.293)" fill="#ddd" />
          <rect id="Rectangle_17812" data-name="Rectangle 17812" width="5.563" height="5.563" transform="translate(102.014 16.857)" fill="#ddd" />
          <rect id="Rectangle_17813" data-name="Rectangle 17813" width="5.563" height="5.563" transform="translate(0.591 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17814" data-name="Rectangle 17814" width="5.563" height="5.563" transform="translate(6.154 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17815" data-name="Rectangle 17815" width="5.991" height="5.563" transform="translate(11.718 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17816" data-name="Rectangle 17816" width="5.563" height="5.563" transform="translate(17.709 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17817" data-name="Rectangle 17817" width="5.563" height="5.563" transform="translate(23.272 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17818" data-name="Rectangle 17818" width="5.563" height="5.563" transform="translate(28.835 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17819" data-name="Rectangle 17819" width="5.563" height="5.563" transform="translate(34.398 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17820" data-name="Rectangle 17820" width="5.563" height="5.563" transform="translate(39.962 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17821" data-name="Rectangle 17821" width="5.991" height="5.563" transform="translate(45.525 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17822" data-name="Rectangle 17822" width="5.563" height="5.563" transform="translate(51.516 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17823" data-name="Rectangle 17823" width="5.563" height="5.563" transform="translate(57.08 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17824" data-name="Rectangle 17824" width="5.563" height="5.563" transform="translate(62.643 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17825" data-name="Rectangle 17825" width="5.563" height="5.563" transform="translate(68.206 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17826" data-name="Rectangle 17826" width="5.991" height="5.563" transform="translate(73.769 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17827" data-name="Rectangle 17827" width="5.563" height="5.563" transform="translate(79.761 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17828" data-name="Rectangle 17828" width="5.563" height="5.563" transform="translate(85.324 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17829" data-name="Rectangle 17829" width="5.563" height="5.563" transform="translate(90.887 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17830" data-name="Rectangle 17830" width="5.563" height="5.563" transform="translate(96.45 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17831" data-name="Rectangle 17831" width="5.563" height="5.563" transform="translate(102.014 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17832" data-name="Rectangle 17832" width="5.991" height="5.563" transform="translate(107.577 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17833" data-name="Rectangle 17833" width="5.563" height="5.563" transform="translate(6.154 33.547)" fill="#ddd" />
          <rect id="Rectangle_17834" data-name="Rectangle 17834" width="5.563" height="5.563" transform="translate(0.591 39.11)" fill="#ddd" />
          <rect id="Rectangle_17835" data-name="Rectangle 17835" width="5.563" height="5.563" transform="translate(17.709 33.547)" fill="#ddd" />
          <rect id="Rectangle_17836" data-name="Rectangle 17836" width="5.991" height="5.563" transform="translate(11.718 39.11)" fill="#ddd" />
          <rect id="Rectangle_17837" data-name="Rectangle 17837" width="5.563" height="5.563" transform="translate(28.835 33.547)" fill="#ddd" />
          <rect id="Rectangle_17838" data-name="Rectangle 17838" width="5.563" height="5.563" transform="translate(23.272 39.11)" fill="#ddd" />
          <rect id="Rectangle_17839" data-name="Rectangle 17839" width="5.563" height="5.563" transform="translate(39.962 33.547)" fill="#ddd" />
          <rect id="Rectangle_17840" data-name="Rectangle 17840" width="5.563" height="5.563" transform="translate(34.398 39.11)" fill="#ddd" />
          <rect id="Rectangle_17841" data-name="Rectangle 17841" width="5.563" height="5.563" transform="translate(51.516 33.547)" fill="#ddd" />
          <rect id="Rectangle_17842" data-name="Rectangle 17842" width="5.991" height="5.563" transform="translate(45.525 39.11)" fill="#ddd" />
          <rect id="Rectangle_17843" data-name="Rectangle 17843" width="5.563" height="5.563" transform="translate(62.643 33.547)" fill="#ddd" />
          <rect id="Rectangle_17844" data-name="Rectangle 17844" width="5.563" height="5.563" transform="translate(57.08 39.11)" fill="#ddd" />
          <rect id="Rectangle_17845" data-name="Rectangle 17845" width="5.991" height="5.563" transform="translate(73.769 33.547)" fill="#ddd" />
          <rect id="Rectangle_17846" data-name="Rectangle 17846" width="5.563" height="5.563" transform="translate(68.206 39.11)" fill="#ddd" />
          <rect id="Rectangle_17847" data-name="Rectangle 17847" width="5.563" height="5.563" transform="translate(85.324 33.547)" fill="#ddd" />
          <rect id="Rectangle_17848" data-name="Rectangle 17848" width="5.563" height="5.563" transform="translate(79.761 39.11)" fill="#ddd" />
          <rect id="Rectangle_17849" data-name="Rectangle 17849" width="5.563" height="5.563" transform="translate(96.45 33.547)" fill="#ddd" />
          <rect id="Rectangle_17850" data-name="Rectangle 17850" width="5.563" height="5.563" transform="translate(90.887 39.11)" fill="#ddd" />
          <rect id="Rectangle_17851" data-name="Rectangle 17851" width="5.991" height="5.563" transform="translate(107.577 33.547)" fill="#ddd" />
          <rect id="Rectangle_17852" data-name="Rectangle 17852" width="5.563" height="5.563" transform="translate(102.014 39.11)" fill="#ddd" />
          <rect id="Rectangle_17853" data-name="Rectangle 17853" width="5.563" height="5.563" transform="translate(0.591 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17854" data-name="Rectangle 17854" width="5.563" height="5.563" transform="translate(6.154 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17855" data-name="Rectangle 17855" width="5.991" height="5.563" transform="translate(11.718 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17856" data-name="Rectangle 17856" width="5.563" height="5.563" transform="translate(17.709 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17857" data-name="Rectangle 17857" width="5.563" height="5.563" transform="translate(23.272 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17858" data-name="Rectangle 17858" width="5.563" height="5.563" transform="translate(28.835 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17859" data-name="Rectangle 17859" width="5.563" height="5.563" transform="translate(34.398 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17860" data-name="Rectangle 17860" width="5.563" height="5.563" transform="translate(39.962 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17861" data-name="Rectangle 17861" width="5.991" height="5.563" transform="translate(45.525 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17862" data-name="Rectangle 17862" width="5.563" height="5.563" transform="translate(51.516 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17863" data-name="Rectangle 17863" width="5.563" height="5.563" transform="translate(57.08 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17864" data-name="Rectangle 17864" width="5.563" height="5.563" transform="translate(62.643 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17865" data-name="Rectangle 17865" width="5.563" height="5.563" transform="translate(68.206 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17866" data-name="Rectangle 17866" width="5.991" height="5.563" transform="translate(73.769 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17867" data-name="Rectangle 17867" width="5.563" height="5.563" transform="translate(79.761 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17868" data-name="Rectangle 17868" width="5.563" height="5.563" transform="translate(85.324 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17869" data-name="Rectangle 17869" width="5.563" height="5.563" transform="translate(90.887 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17870" data-name="Rectangle 17870" width="5.563" height="5.563" transform="translate(96.45 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17871" data-name="Rectangle 17871" width="5.563" height="5.563" transform="translate(102.014 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17872" data-name="Rectangle 17872" width="5.991" height="5.563" transform="translate(107.577 39.11)" fill="#fcfcfc" />
        </g>
        <g id="Group_46039" data-name="Group 46039" transform="translate(-5046.81 -8058.409)">
          <rect id="Rectangle_17874" data-name="Rectangle 17874" width="1.725" height="13.511" transform="translate(0 -0.001)" />
          <rect id="Rectangle_17875" data-name="Rectangle 17875" width="1.724" height="13.511" transform="translate(20.582 -0.001)" />
          <rect id="Rectangle_17876" data-name="Rectangle 17876" width="1.725" height="13.511" transform="translate(9.919 -0.001)" />
          <rect id="Rectangle_17877" data-name="Rectangle 17877" width="3.683" height="13.511" transform="translate(4.704 -0.001)" />
          <rect id="Rectangle_17878" data-name="Rectangle 17878" width="0.527" height="13.511" transform="translate(2.396 -0.001)" />
          <rect id="Rectangle_17879" data-name="Rectangle 17879" width="0.528" height="13.511" transform="translate(11.979 -0.001)" />
          <rect id="Rectangle_17880" data-name="Rectangle 17880" width="0.527" height="13.511" transform="translate(9.057 -0.001)" />
          <rect id="Rectangle_17881" data-name="Rectangle 17881" width="0.527" height="13.511" transform="translate(22.642 -0.001)" />
          <rect id="Rectangle_17882" data-name="Rectangle 17882" width="0.527" height="13.511" transform="translate(19.719 -0.001)" />
          <rect id="Rectangle_17883" data-name="Rectangle 17883" width="3.174" height="13.511" transform="translate(14.43 -0.001)" />
          <rect id="Rectangle_17884" data-name="Rectangle 17884" width="0.971" height="13.511" transform="translate(18.221 -0.001)" />
          <rect id="Rectangle_17885" data-name="Rectangle 17885" width="0.971" height="13.511" transform="translate(12.842 -0.001)" />
          <rect id="Rectangle_17886" data-name="Rectangle 17886" width="0.527" height="13.511" transform="translate(3.594 -0.001)" />
        </g>
      </g>
    </g>
  </svg>
</template>
