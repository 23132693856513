<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.614" height="40.608" viewBox="0 0 40.614 40.608">
    <defs>
      <!-- <clipPath id="clip-path987">
        <rect id="Rectangle_14701" data-name="Rectangle 14701" width="40.614" height="40.608" transform="translate(0 0)" />
      </clipPath> -->
    </defs>
    <g id="Group_37775" data-name="Group 37775" transform="translate(0 0)">
      <!-- <g id="Group_33425" data-name="Group 33425" clip-path="url(#clip-path987)"> -->
      <g id="Group_33425" data-name="Group 33425">
        <path
          id="Path_22466"
          data-name="Path 22466"
          d="M20.306,40.608a20.3,20.3,0,1,1,14.361-5.947,20.3,20.3,0,0,1-14.361,5.947m0-36.547A16.214,16.214,0,1,0,31.8,8.818,16.244,16.244,0,0,0,20.306,4.061Z"
        />
        <g id="Group_37771" data-name="Group 37771" transform="translate(-1552.693 -509.198)">
          <rect
            id="Rectangle_16380"
            data-name="Rectangle 16380"
            width="4.001"
            height="19.001"
            rx="2"
            transform="translate(1580.942 535.086) rotate(137)"
          />
          <rect
            id="Rectangle_16381"
            data-name="Rectangle 16381"
            width="4.001"
            height="19.001"
            rx="2"
            transform="translate(1578.017 521.189) rotate(43)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
