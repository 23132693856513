<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.607" height="21.718" viewBox="0 0 24.607 21.718">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_14209" data-name="Rectangle 14209" width="24.607" height="21.718" />
      </clipPath> -->
    </defs>
    <g id="Group_34507" data-name="Group 34507" transform="translate(0 0)">
      <!-- <g id="Group_31960" data-name="Group 31960" transform="translate(0 0)" clip-path="url(#clip-path)"> -->
      <g id="Group_31960" data-name="Group 31960" transform="translate(0 0)">
        <path
          id="Path_21308"
          data-name="Path 21308"
          d="M14.814,48.248a.451.451,0,0,0,0,.9H15.5v3.833a.451.451,0,0,0,.451.451H16a.451.451,0,0,0,.451-.451V49.15h.685a.451.451,0,0,0,0-.9Z"
          transform="translate(-11.663 -39.179)"
        />
        <path
          id="Path_21309"
          data-name="Path 21309"
          d="M108.5,34.182a.92.92,0,1,0-.92-.92.92.92,0,0,0,.92.92"
          transform="translate(-87.356 -26.263)"
        />
        <path
          id="Path_21310"
          data-name="Path 21310"
          d="M58.238,105.754a.92.92,0,1,0,.92.92.92.92,0,0,0-.92-.92"
          transform="translate(-46.544 -85.876)"
        />
        <path
          id="Path_21311"
          data-name="Path 21311"
          d="M23.553,12.8a1.054,1.054,0,0,0,1.054-1.054V4.728a1.054,1.054,0,0,0-1.054-1.054H19.9V1.687A1.686,1.686,0,0,0,18.211,0H5.6A1.686,1.686,0,0,0,3.915,1.687v4.34H1.054A1.054,1.054,0,0,0,0,7.081v8.825A1.054,1.054,0,0,0,1.054,16.96h2.86v2.932A1.686,1.686,0,0,0,5.6,21.578H8.382a.8.8,0,1,0,0-1.6H5.6a.088.088,0,0,1-.087-.088V16.96H8.074a1.054,1.054,0,0,0,1.054-1.054V7.081A1.054,1.054,0,0,0,8.074,6.027H5.513V1.687A.088.088,0,0,1,5.6,1.6H18.211a.088.088,0,0,1,.088.088V3.674h-3.57a1.054,1.054,0,0,0-1.054,1.054v7.02A1.054,1.054,0,0,0,14.729,12.8H18.3v1.026H14.381a.564.564,0,0,0-.564.564V17.51a.564.564,0,0,0,.564.564H18.3v1.818a.088.088,0,0,1-.088.088H15.217a.8.8,0,1,0,0,1.6h2.994A1.686,1.686,0,0,0,19.9,19.892V18.074h4.075a.564.564,0,0,0,.564-.564V14.393a.564.564,0,0,0-.564-.564H19.9V12.8ZM7.275,7.625a.254.254,0,0,1,.254.255v7.227a.254.254,0,0,1-.254.255H1.853a.254.254,0,0,1-.254-.255V7.88a.254.254,0,0,1,.254-.255H7.275Zm8-2.1a.254.254,0,0,1,.254-.255h7.227a.254.254,0,0,1,.254.255v5.423a.254.254,0,0,1-.195.247L17.492,7.579a1.4,1.4,0,0,0-1.725.119l-.493.445Zm7.665,9.9v1.049H15.415V15.427h7.524ZM18.3,11.2H15.528a.254.254,0,0,1-.254-.255V9.579l.873-.789a.908.908,0,0,1,1.119-.077l3.6,2.49H18.3Z"
          transform="translate(0 -0.001)"
        />
      </g>
    </g>
  </svg>
</template>
