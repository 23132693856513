<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="75.193" height="59.609" viewBox="0 0 75.193 59.609">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_17225" data-name="Rectangle 17225" width="75.193" height="59.609" fill="none" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_45685" data-name="Group 45685" clip-path="url(#clip-path)"> -->
    <g id="Group_45685" data-name="Group 45685">
      <path
        id="Path_29205"
        data-name="Path 29205"
        d="M59.065,22.842h-5.39a7.312,7.312,0,0,0-14.362,0H20.23a1.382,1.382,0,1,0,0,2.763H39.312a7.312,7.312,0,0,0,14.362,0h5.39a1.382,1.382,0,0,0,0-2.763M46.493,29.126a4.914,4.914,0,1,1,4.7-3.521,4.908,4.908,0,0,1-4.7,3.521"
        transform="translate(-3.779 -3.39)"
        fill="#ebeaea"
      />
      <path
        id="Path_29206"
        data-name="Path 29206"
        d="M59.065,62.193h-5.39a7.312,7.312,0,0,0-14.362,0H20.23a1.382,1.382,0,0,0,0,2.763H39.312a7.312,7.312,0,0,0,14.362,0h5.39a1.382,1.382,0,0,0,0-2.763M46.493,68.477a4.914,4.914,0,1,1,4.7-3.521,4.909,4.909,0,0,1-4.7,3.521"
        transform="translate(-3.779 -11.28)"
        fill="#ebeaea"
      />
      <rect id="Rectangle_17217" data-name="Rectangle 17217" width="2.384" height="7.231" transform="translate(26.639)" fill="#ebeaea" />
      <rect id="Rectangle_17218" data-name="Rectangle 17218" width="7.231" height="2.384" transform="translate(24.216 2.424)" fill="#ebeaea" />
      <rect id="Rectangle_17219" data-name="Rectangle 17219" width="1.585" height="4.808" transform="translate(8.172 42.814)" fill="#ebeaea" />
      <rect id="Rectangle_17220" data-name="Rectangle 17220" width="4.808" height="1.585" transform="translate(6.561 44.426)" fill="#ebeaea" />
      <rect
        id="Rectangle_17221"
        data-name="Rectangle 17221"
        width="2.384"
        height="7.231"
        transform="matrix(0.707, -0.707, 0.707, 0.707, 0, 17.18)"
        fill="#cacaca"
      />
      <rect
        id="Rectangle_17222"
        data-name="Rectangle 17222"
        width="7.231"
        height="2.384"
        transform="translate(0 20.608) rotate(-45)"
        fill="#cacaca"
      />
      <rect
        id="Rectangle_17223"
        data-name="Rectangle 17223"
        width="7.231"
        height="2.384"
        transform="translate(68.394 24.926) rotate(-45)"
        fill="#cacaca"
      />
      <rect
        id="Rectangle_17224"
        data-name="Rectangle 17224"
        width="2.384"
        height="7.231"
        transform="translate(68.394 21.498) rotate(-45)"
        fill="#cacaca"
      />
      <path
        id="Path_29207"
        data-name="Path 29207"
        d="M13.839,13.826a1.483,1.483,0,1,1-1.483-1.483,1.484,1.484,0,0,1,1.483,1.483"
        transform="translate(-2.18 -2.475)"
        fill="#ebeaea"
      />
      <path
        id="Path_29208"
        data-name="Path 29208"
        d="M86.956,50.627a1.483,1.483,0,1,1-1.483-1.483,1.484,1.484,0,0,1,1.483,1.483"
        transform="translate(-16.841 -9.854)"
        fill="#cacaca"
      />
      <path
        id="Path_29209"
        data-name="Path 29209"
        d="M67.825,6.843A2.321,2.321,0,1,1,65.5,4.522a2.322,2.322,0,0,1,2.321,2.321"
        transform="translate(-12.669 -0.906)"
        fill="#ebeaea"
      />
      <path
        id="Path_29210"
        data-name="Path 29210"
        d="M20.229,42.678h5.39a7.312,7.312,0,0,1,14.362,0H59.064a1.382,1.382,0,1,1,0,2.763H39.981a7.312,7.312,0,0,1-14.362,0h-5.39a1.382,1.382,0,0,1,0-2.763M32.8,48.962a4.914,4.914,0,1,0-4.7-3.521,4.908,4.908,0,0,0,4.7,3.521"
        transform="translate(-3.779 -7.367)"
        fill="#cbcaca"
      />
    </g>
  </svg>
</template>
