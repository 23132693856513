<template>
  <svg id="Group_28726" data-name="Group 28726" xmlns="http://www.w3.org/2000/svg" width="51.832" height="42.619" viewBox="0 0 51.832 42.619">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_12946" data-name="Rectangle 12946" width="51.832" height="42.619" fill="#006cff" />
      </clipPath> -->
    </defs>
    <g id="Group_28726-2" data-name="Group 28726">
      <path
        id="Path_17242"
        data-name="Path 17242"
        d="M185.047,83.7h-.076a.737.737,0,0,0-.734.734V85.78a5.953,5.953,0,0,0-3.994,1.642,5.707,5.707,0,0,0-1.693,4.259,5.193,5.193,0,0,0,.714,2.719,5.111,5.111,0,0,0,1.814,1.836,19.88,19.88,0,0,0,3.158,1.368v4.565c-.928-.1-1.546-.579-2.164-1.993h-3.508c0,3.122,2.3,5.325,5.672,5.449v1.548a.737.737,0,0,0,.734.734h.076a.737.737,0,0,0,.734-.734v-1.616a6.626,6.626,0,0,0,3.787-1.65,5.753,5.753,0,0,0,1.878-4.429,5.37,5.37,0,0,0-.664-2.705,5.14,5.14,0,0,0-1.742-1.843,16.214,16.214,0,0,0-2.964-1.37l-.3-.115V89.263a2.337,2.337,0,0,1,1.9,1.27h3.508a6.1,6.1,0,0,0-5.413-4.7V84.436a.737.737,0,0,0-.734-.734m-.811,9.082a5.643,5.643,0,0,1-.881-.5,1.551,1.551,0,0,1-.65-1.307,1.664,1.664,0,0,1,.55-1.236,1.866,1.866,0,0,1,.98-.48Zm2.411,6.066a1.938,1.938,0,0,1-.207,2.769,2.345,2.345,0,0,1-.659.372V98.243a2.966,2.966,0,0,1,.866.607"
        transform="translate(-159.116 -74.591)"
        fill="#006cff"
      />
      <path
        id="Path_17243"
        data-name="Path 17243"
        d="M111.924,21.5l-5.63-2.506,1.383-3.052,4.494,1.984-1.087-2.905A18.163,18.163,0,0,0,81.222,8.46H77.033a21.333,21.333,0,0,1,37.1,5.694l1.1,3.073,2.16-4.893L120.48,13.7,116.2,23.388Z"
        transform="translate(-68.648)"
        fill="#006cff"
      />
      <path
        id="Path_17244"
        data-name="Path 17244"
        d="M25.383,214.392a21.31,21.31,0,0,1-19.21-12.077l-1.188-2.467-1.94,4.1L0,202.512l4.612-9.758,9.579,4.528-1.44,3.046-5.3-2.5,1.7,3.388a18.205,18.205,0,0,0,31.437,1.882h3.674a21.356,21.356,0,0,1-18.883,11.3"
        transform="translate(0 -171.773)"
        fill="#006cff"
      />
    </g>
  </svg>
</template>
