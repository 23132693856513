<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="11" viewBox="0 0 5 11">
    <g id="Group_45767" data-name="Group 45767" transform="translate(-12966 -9984)">
      <g id="Group_36550" data-name="Group 36550" transform="translate(0 6)">
        <circle id="Ellipse_767" data-name="Ellipse 767" cx="1" cy="1" r="1" transform="translate(12966 9978)" />
        <circle id="Ellipse_768" data-name="Ellipse 768" cx="1" cy="1" r="1" transform="translate(12966 9981)" />
        <circle id="Ellipse_769" data-name="Ellipse 769" cx="1" cy="1" r="1" transform="translate(12966 9984)" />
        <circle id="Ellipse_770" data-name="Ellipse 770" cx="1" cy="1" r="1" transform="translate(12966 9987)" />
      </g>
      <g id="Group_36551" data-name="Group 36551" transform="translate(3 6)">
        <circle id="Ellipse_767-2" data-name="Ellipse 767" cx="1" cy="1" r="1" transform="translate(12966 9978)" />
        <circle id="Ellipse_768-2" data-name="Ellipse 768" cx="1" cy="1" r="1" transform="translate(12966 9981)" />
        <circle id="Ellipse_769-2" data-name="Ellipse 769" cx="1" cy="1" r="1" transform="translate(12966 9984)" />
        <circle id="Ellipse_770-2" data-name="Ellipse 770" cx="1" cy="1" r="1" transform="translate(12966 9987)" />
      </g>
    </g>
  </svg>
</template>
