export default function segmentProviderModel() {
  const fetchPersonalizeListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignSegment/PersonalizeList`,
    method: 'GET',
    payload: {},
  };

  const fetchSegmentResultModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignSegment/SegmentResult`,
    method: 'POST',
    payload: {
      SegmentId: null,
      Search_Value: null, //028017669, 17/06/2022 13:53:15
      Page: null,
      Limit: null,
      Filters: [],
      Flag_Count: false,
    },
  };

  const addPersonalizeDataModel: API.RequestModel<Segment.Provider.AddData> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/segment_provider/add_data`,
    method: 'POST',
    payload: {
      Channel: null!,
      list_current: null!,
      list_add: null!,
    },
  };

  return { fetchSegmentResultModel, fetchPersonalizeListModel, addPersonalizeDataModel };
}
