<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.32" height="20.698" viewBox="0 0 17.32 20.698">
    <defs>
      <!-- <clipPath id="a">
        <path d="M799,1509.161h7.331v13.9h5.988v-18.123H799Z" transform="translate(993 -1153)" fill="none" stroke-width="1" />
      </clipPath> -->
    </defs>
    <g transform="translate(-1786 -353.302)">
      <path
        d="M1004-1137H994a1,1,0,0,1-1-1v-14a1,1,0,0,1,1-1h10a1,1,0,0,1,1,1v14A1,1,0,0,1,1004-1137Zm-9-14v12h8v-12Z"
        transform="translate(793 1511)"
      />
      <g transform="translate(-3 1.36)">
        <!-- <g transform="translate(1)" clip-path="url(#a)"> -->
        <g transform="translate(1)">
          <path
            d="M1004-1137H994a1,1,0,0,1-1-1v-14a1,1,0,0,1,1-1h10a1,1,0,0,1,1,1v14A1,1,0,0,1,1004-1137Zm-9-14v12h8v-12Z"
            transform="translate(799 1506)"
          />
        </g>
        <rect width="2" height="3" rx="1" transform="translate(1793 355)" />
        <rect width="3" height="2" rx="1" transform="translate(1799 367)" />
      </g>
    </g>
  </svg>
</template>
