import { moduleName } from '@/constants/global/module';
import { useModuleStore } from '@/store/moduleStore';
import { CustomRouteRecord } from '@/types/router/router';

// // Lazy load components
// const DataSegment = () => import('@/views/modules/segment/pages/data-segment/DataSegmentPage.vue');
// const MainSegment = () => import('@/views/modules/segment/pages/data-segment/pages/main/MainPage.vue');
// const FolderInside = () => import('@/views/modules/segment/pages/data-segment/pages/folder-inside/FolderInside.vue');
// const DetailSegmentList = () => import('@/views/modules/segment/pages/data-segment/pages/detail-list/DetailList.vue');
// const PreviewSegment = () => import('@/views/modules/segment/pages/data-segment/pages/preview/PreviewSegment.vue');
// const SegmentTemplate = () => import('@/views/modules/segment/pages/data-segment/pages/segment-template/SegmentTemplate.vue');

import DataSegment from '@/views/modules/segment/pages/data-segment/DataSegmentPage.vue';
import MainSegment from '@/views/modules/segment/pages/data-segment/pages/main/MainPage.vue';
import FolderInside from '@/views/modules/segment/pages/data-segment/pages/folder-inside/FolderInside.vue';
import DetailSegmentList from '@/views/modules/segment/pages/data-segment/pages/detail-list/DetailList.vue';
import PreviewSegment from '@/views/modules/segment/pages/data-segment/pages/preview/PreviewSegment.vue';
import SegmentTemplate from '@/views/modules/segment/pages/data-segment/pages/segment-template/SegmentTemplate.vue';



const meta = {
  moduleName: moduleName.Segment,
  moduleVersion: '1.0.0',
};

const setSegmentRoutes = (): CustomRouteRecord[] => {

  return [
    {
      path: '/segment/data-segment',
      name: 'data-segment',

      component: DataSegment,

      props: {
        selectedSource: Object,
        config: Object,
      },
      breadcrumb: [
        {
          text: 'Segment',
        },
      ],
      children: [
        {
          path: 'main',
          name: 'data-segment-main',
          component: MainSegment,

          props: {
            selectedSource: Object,
            config: Object,
          },
          meta: {
            ...meta,
            isShowSegmentTab: true,
          },
        },
        {
          path: 'folder-inside',
          name: 'data-segment-folder-inside',
          component: FolderInside,
          props: {
            selectedSource: Object,
            config: Object,
          },
          meta: {
            ...meta,
            isShowSegmentTab: true,
          },
        },
        {
          path: 'segment-detail',
          name: 'data-segment-detail-list',
          component: DetailSegmentList,
          props: {
            selectedSource: Object,
            config: Object,
          },
          meta: {
            ...meta,
            isShowSegmentTab: true,
          },
        },
        {
          path: 'preview',
          name: 'data-segment-preview',
          component: PreviewSegment,
          props: {
            selectedSource: Object,
            config: Object,
          },
          meta: {
            ...meta,
            isShowSegmentTab: true,
          },
        },
        {
          path: 'segment-template',
          name: 'data-segment-template',
          component: SegmentTemplate,
          props: {
            selectedSource: Object,
            config: Object,
          },
          meta: {
            ...meta,
            isShowSegmentTab: false,
            breadcrumb: [
              {
                text: 'Segment',
              },
              {
                text: 'Segment Template',
                active: true,
              },
            ],
          },
        },
      ],
    },
  ];
};

const segmentRoutes = setSegmentRoutes();
export default segmentRoutes;
