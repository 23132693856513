// WARN: Import order is important on entrypoint script, do not change if not sure what you are doing :)

// vue and its plugins
import { createApp, Plugin } from 'vue';
import { pinia } from '@/store';
import { i18n } from '@/libs/i18n';
import { VeeValidatePlugin } from '@/libs/vee-validate';
import { abilitiesPlugin } from '@casl/vue';
import ability from '@/libs/acl/ability';
import router from '@/router';
import VueFeather from 'vue-feather';

// styles
import 'vue-select/dist/vue-select.css';
// import '@/assets/styles/app.scss';

// 3rd party global libraries (e.g. bootstrap)
import 'bootstrap';
import '@popperjs/core';
import 'choco-core-package';
import 'choco-edm-core-package';
import '@/libs/edm/EDMBlockPlugin';
import vSelect from 'vue-select';
import VueGridLayout from 'vue-grid-layout';
// entry component
import App from '@/App.vue';

// directive
import vPermission from './directives/permission-directive';

// for boot/setup modules
import { useSettingStore } from '@/store/settingStore';
import '@assets/styles/base/global.scss';
import '@/assets/styles/app.scss';

// app plugins
import btTooltips from '@/plugins/bt-tooltips';
import ganttastic from '@infectoone/vue-ganttastic';

// Toast Notification
import Toast, { PluginOptions } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = { maxToasts: 5 };

// // create vue(3) application
// createApp(App)
//   // centralize state management: pinia
//   .use(pinia)
//   // localization
//   .use(i18n)
//   // data/form validation
//   .use(VeeValidatePlugin)
//   // router: vue-router
//   .use(router)
//   // for boot/setup module before mounting application
//   .use(() => {
//     // setup locale state, load from setting
//     useSettingStore().setupLocale();
//   })
//   .use(abilitiesPlugin, ability, {
//     useGlobalProperties: true,
//   })
//   // app plugins
//   .use(btTooltips)
//   .use(ganttastic)
//   .use(abilitiesPlugin, ability)
//   .use(VueGridLayout)
//   // Toast
//   .use(Toast, toastOptions)
//   .component('v-select', vSelect)
//   .component(VueFeather.name, VueFeather)
//   .directive('permission', vPermission)
//   // mount to index.html#app
//   .mount('#app');

// Create Vue (3) application
const app = createApp(App);
// Initialize Pinia before accessing any stores
app.use(pinia);

// Ensure store-dependent logic is executed after Pinia setup
const settingStore = useSettingStore(pinia);
settingStore.setupLocale();

// Use plugins and libraries
app
  .use(i18n)
  .use(VeeValidatePlugin)
  .use(router)
  // Router is used after Pinia setup
  .use(abilitiesPlugin, ability, { useGlobalProperties: true })
  .use(btTooltips)
  .use(ganttastic as Plugin)
  .use(VueGridLayout)
  .use(Toast, toastOptions)
  .component('v-select', vSelect)
  .component(VueFeather.name, VueFeather)
  .directive('permission', vPermission);

// Mount the app
app.mount('#app');
