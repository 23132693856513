<template>
  <svg
    id="Group_32408"
    data-name="Group 32408"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="17.272"
    height="19.232"
    viewBox="0 0 17.272 19.232"
  >
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_14346" data-name="Rectangle 14346" width="17.272" height="19.232" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_32407" data-name="Group 32407" clip-path="url(#clip-path)"> -->
    <g id="Group_32407" data-name="Group 32407">
      <path
        id="Path_21312"
        data-name="Path 21312"
        d="M13.7,38.61c1.181-1.181.005-4.272-2.627-6.9S5.348,27.9,4.167,29.08a2.287,2.287,0,0,0-.332,2.229c-.379.819-1.227,4.064-1.622,4.459L.956,37.025A3.28,3.28,0,0,0,5.6,41.663l1.257-1.257c.39-.39,3.562-1.153,4.442-1.512a2.4,2.4,0,0,0,2.4-.284m-3.473-6.057c2.545,2.545,3,4.835,2.627,5.21s-2.665-.081-5.21-2.627-3-4.835-2.626-5.21,2.665.081,5.21,2.627M6,39.56,4.748,40.817A2.083,2.083,0,0,1,1.8,37.872L3.06,36.615a16.08,16.08,0,0,0,1.446-3.641,13.2,13.2,0,0,0,2.288,3.01A13.279,13.279,0,0,0,9.727,38.23,16.388,16.388,0,0,0,6,39.56"
        transform="translate(0 -25.598)"
      />
      <path
        id="Path_21313"
        data-name="Path 21313"
        d="M72.285,67.976a1.7,1.7,0,0,0-2.149-.209l2.358,2.358a1.7,1.7,0,0,0-.209-2.149"
        transform="translate(-62.729 -60.353)"
      />
      <path
        id="Path_21314"
        data-name="Path 21314"
        d="M58.59,151.858l1.059-1.257a.461.461,0,0,0-.171-.722l-1.03-.442a.462.462,0,0,0-.536.129l-.189.226a.461.461,0,0,1-.543.126l-1.975-.883a.462.462,0,0,0-.524.1l-.792.84a.461.461,0,0,0,.08.7l2.267,1.512a1.784,1.784,0,0,0,2.353-.335"
        transform="translate(-48.085 -133.26)"
      />
      <path
        id="Path_21315"
        data-name="Path 21315"
        d="M111.61,27.581a.306.306,0,0,0,.411.02l2.055-1.689a.306.306,0,0,0,.022-.453l-.358-.358a.306.306,0,0,0-.452.021l-1.7,2.048a.306.306,0,0,0,.019.412"
        transform="translate(-99.743 -22.369)"
      />
      <path
        id="Path_21316"
        data-name="Path 21316"
        d="M134.021,61.624a.306.306,0,0,0,.379.159l2.509-.885a.306.306,0,0,0,.176-.418l-.214-.459a.306.306,0,0,0-.432-.135l-2.295,1.344a.306.306,0,0,0-.123.394"
        transform="translate(-119.842 -53.524)"
      />
      <path
        id="Path_21317"
        data-name="Path 21317"
        d="M83.2,2.713a.306.306,0,0,0,.553.257L85.1.675a.306.306,0,0,0-.135-.432L84.5.029A.306.306,0,0,0,84.086.2Z"
        transform="translate(-74.399 0)"
      />
    </g>
  </svg>
</template>
