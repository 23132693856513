<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14.458" height="17.279" viewBox="0 0 14.458 17.279">
    <defs>
      <!-- <clipPath id="clip-path">
        <path id="Path_283" data-name="Path 283" d="M799,1508.464h6.12v11.607h5v-15.13H799Z" transform="translate(-799 -1504.942)" />
      </clipPath> -->
    </defs>
    <g id="Group_33266" data-name="Group 33266" transform="translate(0)">
      <path
        id="Subtraction_11"
        data-name="Subtraction 11"
        d="M9.018,13.357H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9.018a1,1,0,0,1,1,1V12.357A1,1,0,0,1,9.018,13.357ZM1.67,1.67V11.687H8.348V1.67Z"
        transform="translate(0 3.922)"
      />
      <g id="Group_516" data-name="Group 516" transform="translate(3.339)">
        <!-- <g id="Mask_Group_12" data-name="Mask Group 12" clip-path="url(#clip-path)"> -->
        <g id="Mask_Group_12" data-name="Mask Group 12">
          <path
            id="Subtraction_12"
            data-name="Subtraction 12"
            d="M9.018,13.357H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9.018a1,1,0,0,1,1,1V12.357A1,1,0,0,1,9.018,13.357ZM1.67,1.67V11.687H8.348V1.67Z"
            transform="translate(0 0.884)"
          />
        </g>
        <rect id="Rectangle_410" data-name="Rectangle 410" width="1.67" height="2.504" rx="0.835" transform="translate(0 2.553)" />
        <rect id="Rectangle_411" data-name="Rectangle 411" width="2.504" height="1.67" rx="0.835" transform="translate(5.009 12.571)" />
      </g>
    </g>
  </svg>
</template>
