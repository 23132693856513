import { acceptHMRUpdate, defineStore } from 'pinia';
import authService from '@services/authentication';
import permissionService from '@/services/permission';
import ability from '@/libs/acl/ability';
// Component
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

export const usePermissionStore = defineStore('permission', {
  state: (): Permission.State => ({
    Info: {} as Record<KeyName, boolean>,
  }),
  getters: { getPermissionStatus: (state) => (key: KeyName) => state.Info[key] },
  actions: {
    async fetchAndAssignPermissions() {
      const { openDefaultErrorModal } = useValidationModal();
      try {
        const respKeys = await permissionService.fetchPermissions();
        const ability_List = [] as Permission.Ability[];
        // Collect updates in a temporary object
        const updatedInfo: Record<string, boolean> = {};

        respKeys.forEach((key: Permission.Response.Key) => {
          updatedInfo[key.permission_key] = key.status;

          if (key.status) {
            ability_List.push({ action: 'portal-cdp', subject: key.permission_key });
          }
        });

        // Update the Info state in a single operation
        this.$patch(updatedInfo);

        ability.update([...ability_List]);
      } catch (err) {
        openDefaultErrorModal(err, () => {
          authService.fetchLogout();
        });
      }
    },
  },
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(usePermissionStore, import.meta.webpackHot));
}
