<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.444" height="20.099" viewBox="0 0 25.444 22.099">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_14394" data-name="Rectangle 14394" width="12.53" height="14.461" />
      </clipPath> -->
    </defs>
    <g id="Group_34637" data-name="Group 34637" transform="translate(-7934.608 9752.833)">
      <g id="Group_33553" data-name="Group 33553" transform="translate(7948.522 -9752.833)">
        <!-- <g id="Group_33299" data-name="Group 33299" transform="translate(0 0)" clip-path="url(#clip-path)"> -->
        <g id="Group_33299" data-name="Group 33299" transform="translate(0 0)">
          <path
            id="Path_22411"
            data-name="Path 22411"
            d="M17.99,1.9l.416,1.637a3.169,3.169,0,0,0,2.313,2.3l1.6.394-1.476.336A3.169,3.169,0,0,0,18.5,8.777L17.99,10.55l-.345-1.612a3.169,3.169,0,0,0-2.537-2.455l-1.446-.26,1.613-.411a3.168,3.168,0,0,0,2.282-2.266Z"
            transform="translate(-9.788 -1.358)"
          />
          <path
            id="Path_22413"
            data-name="Path 22413"
            d="M15.2,24.11l.147.58a1.124,1.124,0,0,0,.82.815l.567.14-.523.119a1.123,1.123,0,0,0-.831.787l-.18.629-.122-.572a1.124,1.124,0,0,0-.9-.87l-.512-.092.572-.146a1.122,1.122,0,0,0,.809-.8Z"
            transform="translate(-9.788 -14.919)"
          />
        </g>
      </g>
      <path
        id="Path_22492"
        data-name="Path 22492"
        d="M3.4,0l.326,1.285a2.487,2.487,0,0,0,1.815,1.8L6.794,3.4,5.635,3.66A2.487,2.487,0,0,0,3.8,5.4L3.4,6.793,3.126,5.527A2.486,2.486,0,0,0,1.135,3.6L0,3.4l1.266-.323A2.487,2.487,0,0,0,3.057,1.3Z"
        transform="translate(7934.608 -9745.203)"
      />
      <path
        id="Path_22493"
        data-name="Path 22493"
        d="M12.105,3.462a4.789,4.789,0,1,0,4.789,4.789,4.789,4.789,0,0,0-4.789-4.789m0,7.566a2.777,2.777,0,1,1,2.777-2.777,2.781,2.781,0,0,1-2.777,2.777"
        transform="translate(7935.25 -9753.453)"
      />
      <path
        id="Path_22494"
        data-name="Path 22494"
        d="M17.927,21.583a14.456,14.456,0,0,0-16.466,0,3.452,3.452,0,0,0,2.013,6.261H15.915a3.452,3.452,0,0,0,2.012-6.261M17.305,24.8a1.422,1.422,0,0,1-1.39,1.037H3.474A1.421,1.421,0,0,1,2.084,24.8a1.328,1.328,0,0,1,.522-1.558,12.447,12.447,0,0,1,14.178,0,1.328,1.328,0,0,1,.522,1.558"
        transform="translate(7937.66 -9758.578)"
      />
    </g>
  </svg>
</template>
