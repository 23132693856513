<template>
  <svg
    id="Group_117351"
    data-name="Group 117351"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30.416"
    height="30.189"
    viewBox="0 0 30.416 30.189"
  >
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_50732" data-name="Rectangle 50732" width="30.416" height="30.189" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_116240" data-name="Group 116240" clip-path="url(#clip-path)"> -->
    <g id="Group_116240" data-name="Group 116240">
      <path
        id="Path_311288"
        data-name="Path 311288"
        d="M0,26.6V10.711A.5.5,0,0,0,.048,10.6a4.053,4.053,0,0,1,2-3.022Q8.266,3.9,14.49.219a1.285,1.285,0,0,1,1.435,0c.534.326,1.075.639,1.613.956q5.377,3.176,10.755,6.352a4.078,4.078,0,0,1,2.113,3.712q.026,7.414,0,14.828a4.69,4.69,0,0,1-.2,1.322,3.817,3.817,0,0,1-3.676,2.8c-2.861.006-5.722,0-8.583,0q-7.009,0-14.018,0A3.681,3.681,0,0,1,.466,28.007,9.386,9.386,0,0,1,0,26.6M27.872,11.927c0-.2,0-.407,0-.611a1.426,1.426,0,0,0-.746-1.353Q21.256,6.5,15.383,3.038a.388.388,0,0,0-.34-.005Q9.129,6.511,3.223,10.008a1.345,1.345,0,0,0-.682,1.227c0,.472.011.944-.007,1.415a.418.418,0,0,0,.253.438c3.778,2.13,7.559,4.254,11.322,6.414a2.009,2.009,0,0,0,2.208-.006q5.344-3.067,10.722-6.063c.611-.34,1.079-.661.834-1.505"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
