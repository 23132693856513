import apiService from '@services/api';
import loginModel from '@models/authentication/login';

class ModuleService {
  async fetchModuleVersions(): Promise<CDPModule.ModuleVersion> {
    try {
      const { fetchAllModuleVersions } = loginModel();
      const versions: Partial<CDPModule.ModuleVersion> = {};
      const response = await apiService.apiRequest(fetchAllModuleVersions);
      response.data.module_versions.forEach(({ module_name, version }: { module_name: string; version: string }) => {
        const moduleName = module_name as keyof CDPModule.ModuleVersion;
        versions[moduleName] = version;
      });

      return versions as CDPModule.ModuleVersion;
    } catch (e) {
      throw e;
    }
  }

  getModuleVersionFromLocalStorage(): boolean {
    const localModuleVersion = localStorage.getItem('moduleVersion');
    return !!localModuleVersion;
  }
}

const moduleService = new ModuleService();
export default moduleService;
