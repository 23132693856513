<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.438" height="16.188" viewBox="0 0 15.438 16.188">
    <defs>
      <!-- <clipPath id="clip-path-awareness">
        <rect id="Rectangle_14391" data-name="Rectangle 14391" width="15.438" height="16.188" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_33293" data-name="Group 33293" clip-path="url(#clip-path-awareness)"> -->
    <g id="Group_33293" data-name="Group 33293">
      <path
        id="Path_22398"
        data-name="Path 22398"
        d="M10.909,3.462A3.592,3.592,0,1,0,14.5,7.054a3.592,3.592,0,0,0-3.592-3.592m0,5.675a2.083,2.083,0,1,1,2.083-2.083,2.086,2.086,0,0,1-2.083,2.083"
        transform="translate(-3.636 -1.721)"
      />
      <path
        id="Path_22399"
        data-name="Path 22399"
        d="M13.449,20.94a10.845,10.845,0,0,0-12.352,0,2.589,2.589,0,0,0,1.51,4.7h9.333a2.589,2.589,0,0,0,1.509-4.7m-.467,2.41a1.067,1.067,0,0,1-1.043.778H2.606a1.066,1.066,0,0,1-1.043-.778,1,1,0,0,1,.391-1.169,9.338,9.338,0,0,1,10.636,0,1,1,0,0,1,.391,1.169"
        transform="translate(0 -9.449)"
      />
      <path
        id="Path_22400"
        data-name="Path 22400"
        d="M23.649,1.01h-.443V.567a.568.568,0,0,0-1.135,0V1.01h-.443a.568.568,0,1,0,0,1.135h.443v.443a.568.568,0,0,0,1.135,0V2.146h.443a.568.568,0,0,0,0-1.135"
        transform="translate(-10.467 0)"
      />
      <path
        id="Path_22404"
        data-name="Path 22404"
        d="M22.528.573h-.251V.322a.322.322,0,0,0-.644,0V.573h-.251a.322.322,0,1,0,0,.644h.251v.251a.322.322,0,0,0,.644,0V1.217h.251a.322.322,0,0,0,0-.644"
        transform="translate(-19.8 6.304)"
      />
      <path
        id="Path_22401"
        data-name="Path 22401"
        d="M3.557,2.376a1.038,1.038,0,1,1-.44,1.4,1.038,1.038,0,0,1,.44-1.4"
        transform="translate(-1.49 -1.122)"
      />
      <path id="Path_22402" data-name="Path 22402" d="M6.968.6a.56.56,0,1,1-.237.755A.56.56,0,0,1,6.968.6" transform="translate(-3.314 -0.266)" />
      <path
        id="Path_22403"
        data-name="Path 22403"
        d="M28.766,13.7a.56.56,0,1,1-.237.755.56.56,0,0,1,.237-.755"
        transform="translate(-14.148 -6.776)"
      />
    </g>
  </svg>
</template>
