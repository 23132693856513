export default function mkaModel() {
  const fetchCampaignListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/List`,
    method: 'POST',
    payload: {},
  };

  const fetchMkaSummary: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Summary_Stats`,
    method: 'GET',
    payload: {},
  };

  const createMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Create`,
    method: 'POST',
    payload: {},
  };

  const deleteMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Delete`,
    method: 'POST',
    payload: {},
  };

  const fetchMkaCampaignDetail: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Detail`,
    method: 'POST',
    payload: { JourneyId: null! },
  };

  const fetchMkaNodeList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Nodes`,
    method: 'GET',
    payload: {},
  };

  const saveMkaCampaignDetail: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Save`,
    method: 'POST',
    payload: {},
  };

  const updateMkaJourneyRunning: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Update`,
    method: 'POST',
    payload: {},
  };

  const verifyMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Verify`,
    method: 'POST',
    payload: {},
  };

  const publishMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Publish`,
    method: 'POST',
    payload: {},
  };

  const fetchMkaSummaryNodes: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Summary_Node`,
    method: 'POST',
    payload: {
      JourneyId: null!,
    },
  };

  const fetchMkaSummaryOverall: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Summary_Overall`,
    method: 'POST',
    payload: {
      JourneyId: null!,
    },
  };

  const fetchMkaOverallReport: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Report_Overall`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      Category: null!,
      Type: null!,
    },
    responseType: 'blob',
  };

  const fetchMkaNodeReport: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Report_node`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      NodeId: null!,
      Category: null!,
      Type: null!,
    },
    responseType: 'blob',
  };

  const pauseMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Pause`,
    method: 'POST',
    payload: {
      JourneyId: null!,
    },
  };

  const resumeMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Resume`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      StartDT: null,
      EndDT: null,
    },
  };

  const fetchMkaActionInfo: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/ActionInfo`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      NodeId: null!,
    },
  };

  const fetchMkaActionInfoSegment: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Action_Info_Segment`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      SegmentId: null!,
      TableId: null!,
    },
  };

  const fetchMkaRecipientList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/RecipientList`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      NodeId: null!,
      Filter: <MKA_Nodes.NodeDataInfo.Request.DataTableFilter>{ Search_Query: '', Status: 0, Page: 1, Limit: 10 },
    },
  };

  const fetchDebugMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Debug`,
    method: 'GET',
    payload: {
      JourneyId: null!,
    },
  };

  const cloneMkaCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/Clone_Journey`,
    method: 'POST',
    payload: {
      JourneyId: null!,
    },
  };

  const resendNodeCommOutOfCredit: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/ResendNodeCommOutOfCredit`,
    method: 'POST',
    payload: {
      JourneyId: null!,
      NodeId: null!,
      schedule_mode: null!,
      schedule_dt: null!,
    },
  };

  const fetchOutOfCreditJourneyStat: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/Journey/OutOfCreditJourneyStat`,
    method: 'GET',
    payload: {},
  };

  const fetchAllFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/MKASegment/SegmentFolderList`,
    method: 'POST',
    payload: {
      root_tableId: null,
    },
  };

  const fetchSegmentListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_MKA!}/MKASegment/SegmentList`,
    method: 'POST',
    payload: {
      ref_type: null,
      reference: null,
    },
  };

  return {
    fetchCampaignListModel,
    fetchMkaSummary,
    createMkaCampaign,
    deleteMkaCampaign,
    fetchMkaCampaignDetail,
    fetchMkaNodeList,
    saveMkaCampaignDetail,
    updateMkaJourneyRunning,
    verifyMkaCampaign,
    publishMkaCampaign,
    fetchMkaSummaryNodes,
    fetchMkaSummaryOverall,
    fetchMkaOverallReport,
    fetchMkaNodeReport,
    pauseMkaCampaign,
    resumeMkaCampaign,
    fetchMkaActionInfo,
    fetchMkaRecipientList,
    fetchDebugMkaCampaign,
    cloneMkaCampaign,
    fetchMkaActionInfoSegment,
    fetchOutOfCreditJourneyStat,
    resendNodeCommOutOfCredit,
    fetchAllFolderListModel,
    fetchSegmentListModel,
  };
}
