import { moduleName } from '@/constants/global/module';
import { useModuleStore } from '@/store/moduleStore';
import moduleService from '@services/module';
import {
  createRouter,
  createWebHistory,
  NavigationGuard,
  NavigationGuardNext,
  NavigationGuardWithThis,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';
import authService from '@/services/authentication';
import apiService from '@/services/api';
import { throttle } from 'lodash';

import Preview from '@/views/modules/preview/pages/Preview.vue';
import Landing from '@/views/modules/landing/Landing.vue';
import authenticationRoute from './routes/authentication';
import segmentRoutes from './routes/segment';
import mkaRoutes from './routes/mka';
import dataConnectRoutes from './routes/data-connect';
import communicationRoutes from './routes/communication';
import analyticsRoutes from './routes/analytics';
import reportRoutes from './routes/report';
import settingRoutes from './routes/setting';
import customerInsightRoutes from './routes/customer-insight';
import permissionRoutes from './routes/permission';
import MyAccountRoutes from './routes/My-Account';
import MainNotificationCreditRoutes from './routes/notification-credit';
import SegmentV2 from './routes/segment-v2';

// fall back page
import NotFoundPage from '@/views/modules/unauthorized/404NotFound.vue';

// custom brands
import bangchakAnalyticsRoute from '@/router/routes/custom-brands/bangchak/bangchak';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import loginModel from '@/models/authentication/login';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useRouterBypassStore } from '@/store/routerStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/landing',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/not-found',
    name: 'not-found-page',
    component: NotFoundPage,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/preview/:component',
    name: 'preview-component',
    component: Preview,
  },
  ...authenticationRoute,
  ...dataConnectRoutes,
  ...mkaRoutes,
  ...segmentRoutes,
  ...analyticsRoutes,
  ...communicationRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...permissionRoutes,
  ...customerInsightRoutes,
  // custom brands
  ...bangchakAnalyticsRoute,
  ...MyAccountRoutes,
  ...MainNotificationCreditRoutes,
  ...SegmentV2,

  // markdown preview
  {
    path: '/docs-markdown',
    name: 'docs-markdown',
    component: () => import('@/views/modules/docs-markdown/DocsMarkdown.vue'),
  },
  {
    path: '/docs-markdown-direct',
    name: 'docs-markdown-direct',
    component: () => import('@/views/modules/docs-markdown/test.md'),
  },
];
const { fetchRefreshTokenModel } = loginModel();

const fetchBrandRef = () => {
  return new URLSearchParams(window.location.search).get('brandRef') || sessionStorage.getItem('brandSessionData');
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const baseUrl: string = process.env.BASE_URL || '';
const brand = authService.getLocalBrandData() ? JSON.parse(authService.getLocalBrandData()!)[0] : null;

const throttledApiRequest = throttle(() => {
  const now = Math.floor(Date.now() / 1000);
  // const now = 1727666971; // เอาไว้ Test เอา time มาใส่

  if (authService.getLocalBrandData()) {
    const localData = JSON.parse(authService.getLocalBrandData()!)[0];

    if (!localData) {
      // กรณี Logout // ไม่มี ข้อมูลใน BrandData
      return;
    }
    const accessToken = String(localData.accessToken);
    const refreshToken = String(localData.refreshToken);

    if (accessToken) {
      const tokenDecode = jwtDecode<JwtPayload>(accessToken);
      const exp = tokenDecode.exp; // รับค่า exp จาก tokenDecode

      if (exp !== undefined) {
        if (now > exp) {
          // ถ้าเลยเวลาหมดอายุแล้ว ให้ไปหน้า Login ใหม่
          return;
        } else {
          // ถ้ายังไม่เลยเวลาให้ลบ 1 ชั่วโมง
          const expMinusOneHour = exp - 3600; // 3600 วินาที = 1 ชั่วโมง

          // เช็คเวลาที่ลบ 1 ชั่วโมงแล้วกับเวลาปัจจุบัน
          if (now > expMinusOneHour) {
            try {
              // เรียกใช้ API
              // ตรวจสอบการหมดอายุของ JWT และทำการ refresh token
              fetchRefreshTokenModel.payload.access_token = accessToken;
              fetchRefreshTokenModel.payload.refresh_token = refreshToken;

              // call API refresh token
              apiService
                .apiRequest(fetchRefreshTokenModel)
                .then((res: Record<string, any>) => {
                  let tempBrand: Array<any> = [];
                  if (authService.getLocalBrandData()) {
                    tempBrand = [
                      ...JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
                        return o.brandRef != fetchBrandRef();
                      }),
                    ];
                  }

                  tempBrand.push({
                    brandRef: fetchBrandRef(),
                    brandData: authService.fetchBrandData()?.brandData,
                    accessToken: res.data.access_token,
                    refreshToken: res.data.refresh_token,
                  });

                  // set item local new token
                  authService.setLocalBrandData(JSON.stringify(tempBrand));
                })
                .catch((error) => {
                  console.error('Failed to refresh token:', error);
                });
            } catch (error) {
              console.error('Error calling API:', error);
            }
          }
        }
      }
    }
  }
}, 10000); // ตั้งค่าให้เรียกใช้ครั้งเดียวทุก 10 วินาที 1000 = 1 sec

// const dynamicRoute = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
//   const moduleStore = useModuleStore();
//   console.log('dynamicRoute:', to, moduleStore.getSegmentVersion, moduleName.Segment, to.meta.moduleName);
//   if (to.meta.moduleName && to.meta.moduleName == moduleName.Segment && moduleStore.getSegmentVersion == '2.0.0') {
//
//     if (to.name == 'data-segment-detail-list') {
//       return next();
//     } else {
//       return next({ name: 'segmentV2' });
//     }
//   } else {
//     console.log('dynamicRoute: else');
//     return next();
//   }
//
// };

// interface ModuleRouteRules {
//   [moduleName: string]: {
//     [version: string]: {
//       allowedRoutes: string[]; // Array of route names allowed for this version
//       defaultRedirect: string; // Default route name to redirect if the route is not allowed
//     };
//   };
// }

// Type guard to filter out undefined route names
// const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

// const moduleRouteRules: ModuleRouteRules = {
//   [moduleName.Segment]: {
//     '1.0.0': {
//       allowedRoutes: segmentRoutes.map((r) => r.name as string), // Dynamically map route names
//       defaultRedirect: 'data-segment-main', // Default redirect for v1
//     },
//     '2.0.0': {
//       allowedRoutes: [
//         ...segmentRoutes.map((r) => r.name as string), // Include v1 routes
//         'data-segment-detail-list', // Custom additional route for v2
//       ],
//       defaultRedirect: 'segmentV2', // Default redirect for v2
//     },
//   },
// };
//
// const isRouteAllowed = (moduleName: string, version: string, routeName: string): boolean => {
//   const rules = moduleRouteRules[moduleName]?.[version];
//   if (!rules) return false;
//   return rules.allowedRoutes.includes(routeName);
// };
//
// const getDefaultRedirect = (moduleName: string, version: string): string | null => {
//   return moduleRouteRules[moduleName]?.[version]?.defaultRedirect || null;
// };
//
// const handleRouteRulesTemp1 = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
//   const moduleStore = useModuleStore();
//   if (to.meta.moduleName == moduleName.Segment) {
//     const segmentVersion = moduleStore.getSegmentVersion;
//     const metaModuleName = to.meta.moduleName as string;
//
//     if (metaModuleName && moduleRouteRules[metaModuleName]) {
//       if (!isRouteAllowed(metaModuleName, segmentVersion, to.name as string)) {
//         const redirect = getDefaultRedirect(metaModuleName, segmentVersion) as string;
//         return next({ name: redirect });
//       }
//     }
//   }
//   return next();
// };

// Basic Rule base
const handleRouteRules = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const moduleStore = useModuleStore();
  const hasLocalModuleVersion = moduleService.getModuleVersionFromLocalStorage();
  // TODO : Enhance for case first login and not have local-store
  if (!hasLocalModuleVersion && authService.isUserLoggedIn()) {
    const moduleVersionResp = await moduleService.fetchModuleVersions();
    moduleStore.setModuleVersions(moduleVersionResp);
  }
  //case segment
  const metaModuleVersion = to.meta.moduleVersion as string;
  if (to.meta.moduleName == moduleName.Segment && metaModuleVersion) {
    if (to.name == 'data-segment-detail-list') {
      return next();
    } else if (moduleStore.getSegmentVersion !== metaModuleVersion) {
      switch (moduleStore.getSegmentVersion) {
        case '1.0.0':
          if (to.meta.redirectNotMatchVersion) return next({ name: to.meta.redirectNotMatchVersion as string });
          // Prevent Infinite
          if (to.name == 'data-segment-main') return next();
          else return next({ name: 'data-segment-main' });
        case '2.0.0':
          if (to.meta.redirectNotMatchVersion) return next({ name: to.meta.redirectNotMatchVersion as string });
          // Prevent Infinite
          if (to.name == 'segmentV2') return next();
          else return next({ name: 'segmentV2' });
      }
    }
  }

  return next();
};

// const handleRouteRulesTemp = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
//   const moduleStore = useModuleStore();
//
//   if (to.meta.moduleName == moduleName.Segment) {
//     console.log('segment handleRouteRules');
//     const segmentVersion = moduleStore.getSegmentVersion;
//     const metaModuleName = to.meta.moduleName as string;
//
//     console.log('Routing dynamic check:', { to, from, version: segmentVersion, moduleName: metaModuleName });
//
//     if (metaModuleName && moduleRouteRules[metaModuleName]) {
//       if (isRouteAllowed(metaModuleName, segmentVersion, to.name as string)) {
//         console.log(`Allowed route: ${to.name as string}`);
//         return next(); // Proceed to allowed route
//       } else {
//         const redirect = getDefaultRedirect(metaModuleName, segmentVersion) as string;
//         // Prevent infinite redirect
//         if (to.name === redirect) {
//           if (from.name !== to.name) return next();
//           console.warn(`Infinite redirect detected: ${to.name}`);
//           return next(false); // Abort navigation
//         }
//
//         console.log(`Redirecting to default route: ${redirect}`);
//         return next({ name: redirect });
//       }
//     }
//   }
//
//   console.log('Default fallback for other modules or routes');
//   return next(); // Allow navigation for undefined modules
//
//   // const moduleVersion = moduleStore[to.meta.moduleName as string];
//
//   // console.log('dynamicRoute:', to, moduleStore.getSegmentVersion, moduleName.Segment, to.meta.moduleName);
//   //
//   // // Segment
//   // // Force to V2 if module == 2.0.0 [1 -> 2]
//   // if (to.meta.moduleName && to.meta.moduleName === moduleName.Segment && moduleStore.getSegmentVersion === '2.0.0') {
//   //   if (to.name === 'segmentV2') {
//   //     console.log('Already on segmentV2');
//   //     return next(); // Avoid redirecting to itself
//   //   } else if (to.name === 'data-segment-detail-list') {
//   //     return next();
//   //   } else {
//   //     console.log('return next({ name: segmentV2 });');
//   //     return next({ name: 'segmentV2' });
//   //   }
//   // }
//   // // Do not go to V2 if api v1 [2 -> 1]
//   // else if (to.meta.moduleVersion == '2.0.0' && to.meta.moduleName === moduleName.Segment && moduleStore.getSegmentVersion === '1.0.0') {
//   //   console.log('Do not go to V2 if api v1 [2 -> 1]');
//   //   if (to.name === 'data-segment-main') {
//   //     console.log('Already on segmentV1');
//   //     return next(); // Avoid redirecting to itself
//   //   }
//   //
//   //   return next({ name: 'data-segment-main' });
//   // }
//   //
//   //
//   // console.log('dynamicRoute: else');
//   // return next();
//
//   // Handle Segment module routing
//   // if (to.meta.moduleName === moduleName.Segment) {
//   //   const version = moduleStore.getSegmentVersion;
//   //
//   //   if (version === '2.0.0') {
//   //     // Allow only data-segment-detail-list in segmentv1
//   //     if (to.name === 'data-segment-detail-list') {
//   //       console.log('Navigating to allowed v1 route: data-segment-detail-list');
//   //       return next();
//   //     }
//   //
//   //     // Restrict access to v1; only v2 routes are allowed otherwise
//   //     if (SegmentV2.map((r) => r.name).includes(to.name as string)) {
//   //       console.log(`Navigating to v2 route: ${to.name as string}`);
//   //       return next();
//   //     }
//   //
//   //     // Redirect to segmentV2 main if invalid
//   //     console.log('Redirecting to segmentV2');
//   //     return next({ name: 'segmentV2' });
//   //   }
//   //
//   //   if (version === '1.0.0') {
//   //     // Allow only v1 routes
//   //     if (segmentRoutes.map((r) => r.name).includes(to.name as string)) {
//   //       console.log(`Navigating to v1 route: ${to.name as string}`);
//   //       return next();
//   //     }
//   //
//   //     // Redirect to data-segment-main if invalid
//   //     console.log('Redirecting to data-segment-main');
//   //     return next({ name: 'data-segment-main' });
//   //   }
//   // }
//   //
//   // // Allow other modules to pass through
//   // console.log('Default routing for other modules or routes');
//   // return next();
// };

router.beforeEach(async (to, from, next) => {
  const { openWarningModal } = useValidationModal();
  const routerStore = useRouterBypassStore();

  const isUserLoggedIn = authService.isUserLoggedIn();
  const currentRoute = window.location;

  throttledApiRequest();

  // bypass skip check login if path = verify
  if (to.name == 'verify-account') {
    return next();
  }

  if (!to.query.brandRef && (sessionStorage.getItem('brandSessionData') || brand)) {
    return next({ path: to.fullPath, query: { ...to.query, brandRef: sessionStorage.getItem('brandSessionData') || brand.brandRef } });
  }

  // check user login
  if (!isUserLoggedIn && to.name == 'auth-login') {
    return next();
  }

  if (!canNavigate(to)) {
    if (!isUserLoggedIn && !to.meta.redirectIfLoggedIn) return next({ name: 'auth-login' });
  }

  if (to.name == 'auth-login' && isUserLoggedIn) {
    return next({ name: 'analytics-dashboard' }); // if logged in redirect to dashboard
  }

  if (to.name == 'landing' && currentRoute.pathname !== '/landing') {
    const redirect = currentRoute.pathname.replaceAll(baseUrl, '/') + currentRoute.search;
    return next(redirect);
  }

  if (
    from.meta.requiresConfirmation &&
    !routerStore.isBypassConfirmation &&
    Array.isArray(from.meta.bypassConfirmations) &&
    !from.meta.bypassConfirmations.includes(to.name)
  ) {
    openWarningModal(
      'ต้องการออกจากหน้านี้หรือไม่',
      'ต้องการออกจากหน้านี้หรือไม่',
      () => {
        routerStore.update(true);
        router.push({ name: to.name as string, params: to.params }).then(() => routerStore.update(false));
      },
      () => {},
    );
    return next(false);
  }

  // dynamic route by module version
  return await handleRouteRules(to, from, next);

  //return next();
});

export default router;
