const moduleName: CDPModule.ModuleName = {
  Global: 'Global',
  Segment: 'Segment',
  BusinessIntelligence: 'BusinessIntelligence',
  CustomerInsight: 'CustomerInsight',
  Communication: 'Communication',
  MKA: 'MKA',
  Analytics: 'Analytics',
  DataManagement: 'DataManagement',
  DataSource: 'DataSource',
  DataRelation: 'DataRelation',
  DataConnector: 'DataConnector',
  DataIngestion: 'DataIngestion',
  DataMapping: 'DataMapping',
  DataMerge: 'DataMerge',
};

export { moduleName };
