<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.993" height="16.945" viewBox="0 0 17.993 16.945">
    <defs>
      <!-- <clipPath id="clip-path-doublequote">
        <rect id="Rectangle_14668" data-name="Rectangle 14668" width="17.993" height="16.945" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_33794" data-name="Group 33794" clip-path="url(#clip-path-doublequote)"> -->
      <g id="Group_33794" data-name="Group 33794">

      <path
        id="Path_22566"
        data-name="Path 22566"
        d="M17.993,2.507a.619.619,0,0,1-.457.582q-2.075.647-2.814,3.486a9.218,9.218,0,0,0-.3,3.238h2.961a.605.605,0,0,1,.605.605v5.923a.605.605,0,0,1-.605.605H11.63a.605.605,0,0,1-.605-.605V10.37a14.558,14.558,0,0,1,1.6-6.919A6.55,6.55,0,0,1,17.282.026a.6.6,0,0,1,.711.6ZM7,2.507a.619.619,0,0,1-.457.582q-2.072.647-2.782,3.486a9.883,9.883,0,0,0-.327,3.238H6.4A.605.605,0,0,1,7,10.418v5.923a.605.605,0,0,1-.605.605H.605A.605.605,0,0,1,0,16.341V10.37A14.758,14.758,0,0,1,1.6,3.418,6.4,6.4,0,0,1,6.3.011a.6.6,0,0,1,.7.6Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
