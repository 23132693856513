<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.076" height="20.076" viewBox="0 0 20.076 20.076">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_17187" data-name="Rectangle 17187" width="20.076" height="20.076" fill="#0f2a4e" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_45003" data-name="Group 45003" clip-path="url(#clip-path)"> -->
    <g id="Group_45003" data-name="Group 45003">
      <path
        id="Path_28981"
        data-name="Path 28981"
        d="M10.038,0A10.038,10.038,0,1,0,20.076,10.038,10.038,10.038,0,0,0,10.038,0m0,18.3A8.258,8.258,0,1,1,18.3,10.038,8.267,8.267,0,0,1,10.038,18.3"
        fill="#0f2a4e"
      />
      <path
        id="Path_28982"
        data-name="Path 28982"
        d="M112.876,131.709a1.672,1.672,0,1,0-1.672,1.672,1.674,1.674,0,0,0,1.672-1.672"
        transform="translate(-104.24 -123.756)"
        fill="#0f2a4e"
      />
      <path
        id="Path_28983"
        data-name="Path 28983"
        d="M236.684,130.037a1.672,1.672,0,1,0,1.672,1.672,1.674,1.674,0,0,0-1.672-1.672"
        transform="translate(-223.66 -123.756)"
        fill="#0f2a4e"
      />
      <path
        id="Path_28984"
        data-name="Path 28984"
        d="M130.874,248.9a.611.611,0,0,0-.448.194,4.077,4.077,0,0,1-5.922,0,.62.62,0,0,0-1.07.417v.009a2.626,2.626,0,0,0,1.21,1.813,4.6,4.6,0,0,0,5.643,0,2.626,2.626,0,0,0,1.21-1.813v-.008a.615.615,0,0,0-.623-.61"
        transform="translate(-117.472 -236.873)"
        fill="#0f2a4e"
      />
    </g>
  </svg>
</template>
