import { acceptHMRUpdate, defineStore } from 'pinia';

const moduleVersionConstant = {
  Global: '1.0.0',
  Segment: '1.0.0',
  BusinessIntelligence: '1.0.0',
  CustomerInsight: '1.0.0',
  Communication: '1.0.0',
  MKA: '1.0.0',
  Analytics: '1.0.0',
  DataManagement: '1.0.0',
  DataSource: '1.0.0',
  DataRelation: '1.0.0',
  DataConnector: '1.0.0',
  DataIngestion: '1.0.0',
  DataMapping: '1.0.0',
  DataMerge: '1.0.0',
};

export const useModuleStore = defineStore('module', {
  state: (): CDPModule.ModuleVersion => moduleVersionConstant,
  getters: {
    getGlobalVersion: (state) => state.Global,
    getSegmentVersion: (state) => state.Segment,
    getBusinessIntelligenceVersion: (state) => state.BusinessIntelligence,
    getCustomerInsightVersion: (state) => state.CustomerInsight,
    getCommunicationVersion: (state) => state.Communication,
    getMKAVersion: (state) => state.MKA,
    getAnalyticsVersion: (state) => state.Analytics,
    getDataManagementVersion: (state) => state.DataManagement,
    getDataSourceVersion: (state) => state.DataSource,
    getDataRelationVersion: (state) => state.DataRelation,
    getDataConnectorVersion: (state) => state.DataConnector,
    getDataIngestionVersion: (state) => state.DataIngestion,
    getDataMappingVersion: (state) => state.DataMapping,
    getDataMergeVersion: (state) => state.DataMerge,
  },
  actions: {
    setModuleVersions(versions: CDPModule.ModuleVersion) {
      this.$patch(versions);
    },
    resetModuleVersion() {
      this.$patch(moduleVersionConstant);
    },
  },
  persist: {
    key: 'moduleVersion',
    storage: localStorage,
  },
});

// to add HMR support with vue-cli/webpack, these code must be added to each store file
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useModuleStore, import.meta.webpackHot));
}
