import { initialAbility } from '@libs/acl/config';
import loginModel from '@models/authentication/login';
import apiService from '@services/api';
import { getCurrentInstance } from 'vue';

class AuthService {
  setLocalBrandData(brand: string) {
    return localStorage.setItem('brandData', brand);
  }

  getLocalBrandData() {
    return localStorage.getItem('brandData');
  }

  fetchBrandRef() {
    const searchParams = new URLSearchParams(window.location.search);
    const brandRef: string = searchParams.get('brandRef')!;
    const brand_ref: string = searchParams.get('brand_ref')!;
    if (brandRef || brand_ref) {
      sessionStorage.setItem('brandSessionData', brandRef || brand_ref);
    }
    return brandRef || brand_ref || sessionStorage.getItem('brandSessionData');
  }

  fetchTokenByBrand() {
    return JSON.parse(this.getLocalBrandData()!)?.find((o: Record<string, any>) => {
      return o.brandRef == new URLSearchParams(window.location.search).get('brandRef') || o.brandRef == sessionStorage.getItem('brandSessionData');
    })?.accessToken;
  }

  fetchBrandData() {
    let brand: Record<string, any> = {};
    if (JSON.parse(authService.getLocalBrandData()!)) {
      brand = JSON.parse(authService.getLocalBrandData()!)[0];
    } else {
      brand = JSON.parse(authService.getLocalBrandData()!)?.find((o: Record<string, any>) => {
        return o.brandRef == this.fetchBrandRef();
      });
    }
    return {
      access_token: brand.accessToken,
      refresh_token: brand.refreshToken,
      brandData: brand.brandData,
    };
  }

  isUserLoggedIn() {
    const searchParams = new URLSearchParams(window.location.search);
    const brandRef = searchParams.get('brandRef');
    let brandObj: Record<string, any> = {};

    if (brandRef) {
      const brands = JSON.parse(this.getLocalBrandData()!);
      sessionStorage.setItem('brandSessionData', brandRef);
      brandObj = brands?.find((o: Record<string, any>) => {
        return o.brandRef == brandRef;
      });
    } else {
      const brands = JSON.parse(this.getLocalBrandData()!);
      brandObj = brands?.at(0);
    }

    return brandObj?.brandRef || false;
  }

  isClearLocalStorage() {
    return localStorage.clear();
  }

  // User Auth
  login() {
  }

  async fetchLogout() {
    const { fetchLogoutModel } = loginModel();
    fetchLogoutModel.payload.access_token = authService.fetchTokenByBrand();
    await apiService.apiRequest(fetchLogoutModel);

    authService.clearUserLocalStorage();
  }

  private clearUserLocalStorage() {
    const vm = getCurrentInstance()?.proxy;
    const brands: Array<Record<string, any>> = JSON.parse(authService.getLocalBrandData()!).filter((o: Record<string, any>) => {
      return o.brandRef != sessionStorage.getItem('brandSessionData');
    });

    if (brands.length === 0) {
      localStorage.removeItem('userInfo');
    }
    sessionStorage.removeItem('brandSessionData');
    sessionStorage.removeItem('segment_source_detail');
    authService.setLocalBrandData(JSON.stringify(brands));
    // Disconnect SignalR
    // signalRService.disconnect();
    vm?.$ability.update(initialAbility);
    window.location.href = '/login';
  }
}

const authService = new AuthService();

export default authService;
