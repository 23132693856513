import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';

import ChannelPage from '@views/modules/communication/pages/main/ChannelPage.vue';
import CreateSmsCampaignPage from '@/views/modules/communication/pages/sms-campaign/CreateSmsCampaignPage.vue';
import EditSmsCampaignPage from '@/views/modules/communication/pages/sms-campaign/EditSmsCampaignPage.vue';
import AllTemplateList from '@/views/modules/communication/pages/all-template/AllTemplateList.vue';
import LineOACreateCampaignPage from '@/views/modules/communication/pages/line-oa-campaign/LineOACreateCampaignPage.vue';
import LineOAEditCampaignPage from '@/views/modules/communication/pages/line-oa-campaign/LineOAEditCampaignPage.vue';
import LineOACreateTemplate from '@/views/modules/communication/pages/line-oa-template/CreateTemplate.vue';
import LineOAEditTemplate from '@/views/modules/communication/pages/line-oa-template/EditTemplate.vue';
import CreateEDMCampaignPage from '@/views/modules/communication/pages/edm-campaign/CreateEDMCampaignPage.vue';
import EditEDMCampaignPage from '@/views/modules/communication/pages/edm-campaign/EditEDMCampaignPage.vue';
import EdmCreateTemplatePage from '@/views/modules/communication/pages/edm-template/CreateEdmTemplate.vue';
import EdmEditTemplatePage from '@/views/modules/communication/pages/edm-template/EditEdmTemplate.vue';
import EdmDashboardByCampaign from '@/views/modules/communication/pages/main/components/edm-campaign-dashboard/EdmDashboardByCampaign.vue';
import LineOaDashboardByCampaign from '@/views/modules/communication/pages/main/components/line-oa-campaign-dashboard/LineOaDashboardByCampaign.vue';
import EdmDashboardExportPreviewVue
  from '@/views/modules/communication/pages/main/components/edm-campaign-dashboard/dashboard/EdmDashboardExportPreviewVue.vue';
import SmsDashboardExportPreviewVue from '@/views/modules/communication/pages/sms-dashboard/SmsDashboardExportPreviewVue.vue';
import LineOaDashboardExportPreviewVue from '@/views/modules/communication/pages/line-oa-dashboard/LineOaDashboardExportPreviewVue.vue';
import AllDashboardExportPreviewVue
  from '@/views/modules/communication/pages/main/components/all-channel-tab/dashboard-all-channel/AllDashboardExportPreviewVue.vue';

import SMSByCampaign from '@/views/modules/communication/pages/main/components/sms-campaign-dashboard/SMSByCampaign.vue';
//template
import CreateSmsTemplate from '@views/modules/communication/pages/sms-template/CreateSmsTemplate.vue';
import EditSmsTemplate from '@views/modules/communication/pages/sms-template/EditSmsTemplate.vue';

import MainResumeCampaign from '@views/modules/communication/pages/resume-campaign/MainResumeCampaignPage.vue';
//Limitation
import LimitationPage from '@views/modules/communication/pages/main/LimitationPage.vue';

const meta = {
  moduleName: moduleName.Communication,
  moduleVersion: '1.0.0',
};

const communicationRoutes: Array<CustomRouteRecord> = [
  {
    path: '/dashboard',
    name: 'dash-board',
    component: () => import('@views/modules/unauthorized/Unauthorized.vue'),
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Dashboard',
        active: true,
      },
    ],
  },
  {
    path: '/communication',
    name: 'communication',
    component: ChannelPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
      },
    ],
  },
  {
    path: '/communication/limitation',
    name: 'Limitation',
    component: LimitationPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Limitation',
        active: true,
      },
    ],
  },

  {
    path: '/communication/template/list',
    name: 'all-template',
    component: AllTemplateList,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
      },
    ],
  },
  {
    path: '/coupon-code-management',
    name: 'coupon-code-management',
    component: () => import('@views/modules/unauthorized/Unauthorized.vue'),
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Coupon Code Management',
        active: true,
      },
    ],
  },
  //#region sms
  {
    path: '/communication/sms/create-campaign/:id',
    name: 'create-sms-campaign',
    component: CreateSmsCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Create Campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/edit-campaign/:id',
    name: 'edit-sms-campaign',
    component: EditSmsCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Edit Campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/template/create',
    name: 'sms-create-template',
    component: CreateSmsTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Create Template',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/template/info/:id',
    name: 'sms-edit-template',
    component: EditSmsTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Edit Template',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/dashboard-by-campaign/:id',
    name: 'sms-dashboard-by-campaign',
    component: SMSByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Campaign List',
        active: true,
        to: '/communication',
        query: { channel: 'sms', tab: 2 },
      },
      {
        text: 'Dashboard',
      },
    ],
  },
  //#endregion sms

  //#region line-oa
  {
    path: '/communication/line-oa/campaign/create',
    name: 'line-oa-campaign-create',
    component: LineOACreateCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Line OA',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Campaign',
      },
      {
        text: 'Create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/campaign/edit',
    name: 'line-oa-campaign-info',
    component: LineOAEditCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line OA',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Campaign',
      },
      {
        text: 'Campaign Info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/template/create',
    name: 'line-oa-template-create',
    component: LineOACreateTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line OA',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Template',
      },
      {
        text: 'Create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/template/info/:id',
    name: 'line-oa-template-info',
    component: LineOAEditTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line OA',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Template',
      },
      {
        text: 'Info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/dashboard-by-campaign/:id',
    name: 'line-oa-dashboard-by-campaign',
    component: LineOaDashboardByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'Line OA',
        active: true,
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Campaign List',
        active: true,
        to: '/communication',
        query: { channel: 'line-oa', tab: 2 },
      },
      {
        text: 'Dashboard',
      },
    ],
  },
  //#endregion line-oa

  //region email
  {
    path: '/communication/email/create-campaign/:id',
    name: 'create-edm-campaign',
    component: CreateEDMCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Create Campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/edit-campaign/:id',
    name: 'edit-edm-campaign',
    component: EditEDMCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
      },
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Edit Campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/template/create',
    name: 'email-template-create',
    component: EdmCreateTemplatePage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Email',
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Template',
      },
      {
        text: 'Create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/template/info/:id',
    name: 'email-template-info',
    component: EdmEditTemplatePage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Email',
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Template',
      },
      {
        text: 'Info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/dashboard-by-campaign/:id',
    name: 'edm-dashboard-by-campaign',
    component: EdmDashboardByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Campaign List',
        active: true,
        to: '/communication',
        query: { channel: 'email', tab: 2 },
      },
      {
        text: 'Dashboard',
      },
    ],
  },
  //#endregion email
  {
    path: '/communication/all-export-preview',
    name: 'all-export-preview',
    component: AllDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'Dashboard',
      },
    ],
  },
  {
    path: '/communication/edm-export-preview',
    name: 'edm-export-preview',
    component: EdmDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'Dashboard',
      },
    ],
  },
  {
    path: '/communication/sms-export-preview',
    name: 'sms-export-preview',
    component: SmsDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'Dashboard',
      },
    ],
  },
  {
    path: '/communication/line-export-preview',
    name: 'line-export-preview',
    component: LineOaDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'Dashboard',
      },
    ],
  },
  {
    path: '/communication/resume-campaign',
    name: 'resume-campaign',
    component: MainResumeCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'communication.communication', //communication
        active: true,
        to: '/communication',
      },
      {
        text: 'communication.campaign_list_paused', //Not enough credit Campaigns
      },
    ],
  },
];

export default communicationRoutes;
