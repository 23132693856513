<template>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- <g clip-path="url(#clip0_2691_29977)"> -->
    <g>
      <path
        d="M85.4209 36.4595H86.2609C86.9209 38.9695 89.2109 40.6795 91.7409 40.6795C92.1209 40.6795 92.4909 40.6495 92.8709 40.5595C95.0009 40.1195 96.6709 38.5295 97.2209 36.4595H116.021C116.821 36.4595 117.481 35.8095 117.481 34.9995C117.481 34.1895 116.831 33.5395 116.021 33.5395H97.2209C96.4609 30.6595 93.5609 28.8295 90.6109 29.4395C88.4809 29.8795 86.8109 31.4695 86.2609 33.5395H85.4209C84.6209 33.5395 83.9609 34.1895 83.9609 34.9995C83.9609 35.8095 84.6109 36.4595 85.4209 36.4595ZM91.7409 32.2395C93.2609 32.2395 94.4909 33.4795 94.4909 34.9895C94.4909 36.4995 93.2509 37.7395 91.7409 37.7395C90.2309 37.7395 88.9909 36.4995 88.9909 34.9895C88.9909 33.4795 90.2309 32.2395 91.7409 32.2395Z"
        fill="#007FFF"
      />
      <path
        d="M102.351 61.6298C104.481 61.1898 106.151 59.5998 106.701 57.5298H116.021C116.821 57.5298 117.481 56.8798 117.481 56.0698C117.481 55.2598 116.831 54.6098 116.021 54.6098H106.701C105.941 51.7298 103.041 49.8998 100.091 50.5098C97.9609 50.9498 96.2909 52.5398 95.7409 54.6098H85.4209C84.6209 54.6098 83.9609 55.2598 83.9609 56.0698C83.9609 56.8798 84.6109 57.5298 85.4209 57.5298H95.7409C96.4009 60.0398 98.6909 61.7498 101.221 61.7498C101.601 61.7498 101.971 61.7198 102.351 61.6298ZM98.4609 56.0698C98.4609 54.5498 99.7009 53.3198 101.211 53.3198C102.721 53.3198 103.961 54.5598 103.961 56.0698C103.961 57.5798 102.721 58.8198 101.211 58.8198C99.7009 58.8198 98.4609 57.5798 98.4609 56.0698Z"
        fill="#007FFF"
      />
      <path
        d="M85.4209 46.9896H106.331C106.691 48.3396 107.511 49.4896 108.681 50.2596C109.621 50.8796 110.701 51.1996 111.791 51.1996C112.171 51.1996 112.551 51.1596 112.941 51.0796C114.421 50.7796 115.701 49.9196 116.541 48.6496C117.381 47.3796 117.671 45.8696 117.371 44.3896C116.831 41.7596 114.501 39.8496 111.811 39.8496C109.121 39.8496 106.991 41.5996 106.331 44.0596H85.4209C84.6209 44.0596 83.9609 44.7096 83.9609 45.5196C83.9609 46.3296 84.6109 46.9796 85.4209 46.9796V46.9896ZM111.821 42.7796C113.341 42.7796 114.571 44.0196 114.571 45.5296C114.571 47.0396 113.331 48.2796 111.821 48.2796C110.311 48.2796 109.071 47.0396 109.071 45.5296C109.071 44.0196 110.311 42.7796 111.821 42.7796Z"
        fill="#007FFF"
      />
      <path d="M111.869 16.5092V3.19922H109.789V16.5092H111.869Z" fill="#007FFF" />
      <path d="M104.18 10.8905H117.49V8.81055H104.18V10.8905Z" fill="#007FFF" />
      <path d="M3.72 117.511L13.13 108.101L11.66 106.631L2.25 116.041L3.72 117.511Z" fill="#007FFF" />
      <path d="M2.25 108.101L11.66 117.511L13.13 116.041L3.72 106.631L2.25 108.101Z" fill="#007FFF" />
      <path d="M39.8504 49.2402H15.9004V73.1902H39.8504V49.2402Z" fill="#007FFF" />
      <path d="M25.8804 17.0801H78.1604V41.0301H15.9004V27.0601C15.9004 21.5501 20.3704 17.0801 25.8804 17.0801Z" fill="#003366" />
      <path d="M15.9004 80.5293H39.8504V104.479H25.8804C20.3704 104.479 15.9004 100.009 15.9004 94.4993V80.5293Z" fill="#007FFF" />
      <path
        d="M10.6594 65.7497C11.4794 66.4997 12.7594 66.4297 13.5094 65.6097C14.2594 64.7897 14.1894 63.5097 13.3694 62.7597C12.5494 62.0097 11.2694 62.0797 10.5194 62.8997C9.76943 63.7197 9.83943 64.9997 10.6594 65.7497Z"
        fill="#003366"
      />
      <path
        d="M114.59 116.96C115.21 117.64 116.26 117.7 116.94 117.08C117.62 116.46 117.68 115.41 117.06 114.73C116.44 114.05 115.39 113.99 114.71 114.61C114.03 115.23 113.97 116.28 114.59 116.96Z"
        fill="#003366"
      />
      <path
        d="M106.269 112.97C107.909 114.78 110.709 114.92 112.519 113.28C114.329 111.64 114.469 108.84 112.829 107.03C111.189 105.22 108.389 105.08 106.579 106.72C104.769 108.36 104.629 111.16 106.269 112.97Z"
        fill="#003366"
      />
      <path
        d="M75.7302 43.71H14.0302V26.57C14.0302 23.63 15.2602 20.76 17.4002 18.69C19.5402 16.62 22.3802 15.47 25.4002 15.47H94.6102C97.6202 15.47 100.46 16.62 102.6 18.69C104.74 20.76 105.96 23.63 105.97 26.57V27.02C105.97 28.13 106.87 29.04 107.99 29.04C109.1 29.04 110.01 28.14 110.01 27.02V26.57C110.01 22.63 108.41 18.92 105.53 16.13C102.66 13.35 98.6802 11.75 94.6202 11.75H25.4002C21.3302 11.75 17.3502 13.35 14.4802 16.13C11.5902 18.93 10.0002 22.64 10.0002 26.57V55.57C10.0302 56.02 10.2302 56.45 10.5802 56.78C10.9602 57.13 11.4802 57.34 12.0202 57.34C12.5602 57.34 13.0802 57.14 13.4602 56.78C13.8102 56.45 14.0102 56.02 14.0302 55.57V47.43H41.8302V75.01H17.8102C17.2902 75.04 16.7902 75.25 16.4302 75.61C16.0902 75.95 15.9002 76.4 15.9002 76.87C15.9002 77.34 16.0902 77.78 16.4302 78.13C16.7702 78.48 17.2902 78.7 17.8102 78.73H41.8302V106.29H25.3902C22.3802 106.29 19.5402 105.14 17.4002 103.07C15.2702 101 14.0402 98.13 14.0302 95.19V72.48C14.0002 72.03 13.8002 71.6 13.4502 71.27C13.0702 70.92 12.5502 70.71 12.0102 70.71C11.4702 70.71 10.9502 70.91 10.5702 71.27C10.2202 71.6 10.0202 72.02 9.99023 72.48V95.19C9.99023 99.13 11.5902 102.84 14.4702 105.64C17.3402 108.42 21.3202 110.02 25.3902 110.02H94.5902C98.6602 110.02 102.64 108.42 105.51 105.64C108.39 102.84 109.99 99.14 109.99 95.2V66.3C109.99 65.19 109.09 64.28 107.97 64.28C106.86 64.28 105.95 65.18 105.95 66.3V75.02H78.1502V47.45V46.16C78.1502 44.82 77.0602 43.73 75.7202 43.73L75.7302 43.71ZM45.8702 47.43H74.1302V75H45.8602V47.43H45.8702ZM74.1402 106.29H45.8602V78.73H74.1402V106.29ZM105.97 95.19C105.97 98.13 104.74 101 102.6 103.07C100.46 105.14 97.6202 106.29 94.6002 106.29H78.1602V78.73H105.96V95.19H105.97Z"
        fill="#003366"
      />
      <path
        d="M7.68906 11.3996C9.42906 11.3996 10.8391 9.98961 10.8391 8.24961C10.8391 6.50961 9.42906 5.09961 7.68906 5.09961C5.94906 5.09961 4.53906 6.50961 4.53906 8.24961C4.53906 9.98961 5.94906 11.3996 7.68906 11.3996Z"
        fill="#003366"
      />
    </g>
    <defs>
      <!-- <clipPath id="clip0_2691_29977">
        <rect width="120" height="120" fill="white" />
      </clipPath> -->
    </defs>
  </svg>
</template>
