<template>
  <svg
    id="Group_34683"
    data-name="Group 34683"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18.124"
    height="16.595"
    viewBox="0 0 18.124 16.595"
  >
    <defs>
      <!-- <clipPath id="clip-path-notification">
        <rect id="Rectangle_15099" data-name="Rectangle 15099" width="18.124" height="16.595" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_34682" data-name="Group 34682" clip-path="url(#clip-path-notification)"> -->
    <g id="Group_34682" data-name="Group 34682">
      <path
        id="Path_22754"
        data-name="Path 22754"
        d="M12.01,7.389a2.66,2.66,0,1,0-2.661-2.66,2.66,2.66,0,0,0,2.661,2.66m0-3.985a1.324,1.324,0,1,1-1.324,1.324A1.325,1.325,0,0,1,12.01,3.4"
        transform="translate(-1.245 -0.275)"
      />
      <path
        id="Path_22755"
        data-name="Path 22755"
        d="M13.463,11.231a2.815,2.815,0,0,1-1.5-2.2.68.68,0,0,0-.672-.563.688.688,0,0,0-.683.791,4.171,4.171,0,0,0,2.281,3.218c.075.035.107.08.107.092a.076.076,0,0,1-.076.079H2.969s-.077-.085-.077-.088c0-.055.041-.112.14-.169A4.459,4.459,0,0,0,5.076,8.529V7.054A3.359,3.359,0,0,1,7.152,3.735a.69.69,0,1,0-.406-1.319A4.732,4.732,0,0,0,3.7,7.054V8.529A3.1,3.1,0,0,1,2.344,11.2a1.536,1.536,0,0,0-.824,1.28v.06a1.452,1.452,0,0,0,1.452,1.481H5.829a2.169,2.169,0,0,0,4.3,0h2.706a1.517,1.517,0,0,0,1.509-1.167,1.47,1.47,0,0,0-.877-1.626"
        transform="translate(-0.202 -0.317)"
      />
      <path
        id="Path_22756"
        data-name="Path 22756"
        d="M1.529,3.991c0,.05,0,.1,0,.151a.46.46,0,0,0,.458.437A.45.45,0,0,0,2.444,4.1c0-.037,0-.075,0-.112A2.235,2.235,0,0,1,4.059,1.847.458.458,0,0,0,4.4,1.409V1.4A.46.46,0,0,0,3.8.962,3.154,3.154,0,0,0,1.529,3.991"
        transform="translate(-0.204 -0.125)"
      />
      <path
        id="Path_22757"
        data-name="Path 22757"
        d="M1.7.049A3.2,3.2,0,0,0,.006,2.34a.427.427,0,0,0,.424.494H.509a.419.419,0,0,0,.414-.341A2.282,2.282,0,0,1,2.084.884.444.444,0,0,0,2.332.5V.426A.43.43,0,0,0,1.7.049"
        transform="translate(0 0)"
      />
      <path
        id="Path_22758"
        data-name="Path 22758"
        d="M18.94,14.595c0-.05,0-.1,0-.151a.46.46,0,0,0-.458-.437.45.45,0,0,0-.453.476c0,.037,0,.075,0,.112a2.235,2.235,0,0,1-1.618,2.144.458.458,0,0,0-.337.438v.01a.46.46,0,0,0,.592.438,3.154,3.154,0,0,0,2.275-3.029"
        transform="translate(-2.141 -1.865)"
      />
      <path
        id="Path_22759"
        data-name="Path 22759"
        d="M18.85,18.66a3.2,3.2,0,0,0,1.695-2.29.427.427,0,0,0-.424-.494h-.079a.419.419,0,0,0-.414.341,2.282,2.282,0,0,1-1.161,1.609.444.444,0,0,0-.248.387v.072a.43.43,0,0,0,.631.376"
        transform="translate(-2.426 -2.114)"
      />
    </g>
  </svg>
</template>
