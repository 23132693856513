<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23" height="23" viewBox="0 0 23 23">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect width="5" height="10" transform="translate(-0.359)" fill="#ffce00" stroke="#ffce00" stroke-width="1" />
      </clipPath> -->
    </defs>
    <g transform="translate(-783.498 -232.896)">
      <g transform="translate(13)">
        <g transform="translate(770.498 232.896)">
          <path d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z" fill="#ffd62f" />
        </g>
        <g transform="translate(775.203 237.676)">
          <g transform="translate(-0.34 -3)">
            <path d="M7.671,9.922a1.37,1.37,0,1,1-1.37,1.37,1.369,1.369,0,0,1,1.37-1.37" transform="translate(-0.409 3.329)" />
            <path
              d="M7.763,3.508h0A1.719,1.719,0,0,1,9.381,5.323c0,.027,0,.053-.006.08L9.052,11.11A1.336,1.336,0,0,1,7.78,12.429,1.334,1.334,0,0,1,6.509,11.12l-.361-5.7a1.72,1.72,0,0,1,1.52-1.9c.031,0,.063-.006.1-.008"
              transform="translate(-0.501 -0.287)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
