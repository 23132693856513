import { markRaw } from 'vue';
//alignment
import AlignStartIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignStartIcon.vue';
import AlignCenterIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignCenterIcon.vue';
import AlignEndIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignEndIcon.vue';
import JustifyStartIcon from '@assets/icons/modules/communication/edm-editor/alignment/JustifyStartIcon.vue';
import JustifyCenterIcon from '@assets/icons/modules/communication/edm-editor/alignment/JustifyCenterIcon.vue';
import JustifyEndIcon from '@assets/icons/modules/communication/edm-editor/alignment/JustifyEndIcon.vue';
///border
import SolidLineIcon from '@assets/icons/modules/communication/edm-editor/border/SolidLineIcon.vue';
import DottedLineIcon from '@assets/icons/modules/communication/edm-editor/border/DottedLineIcon.vue';
import DashedLineIcon from '@assets/icons/modules/communication/edm-editor/border/DashedLineIcon.vue';
///icon-styles
import CircleStyleIcon from '@assets/icons/modules/communication/edm-editor/social/icon-styles/CircleStyleIcon.vue';
import PngStyleIcon from '@assets/icons/modules/communication/edm-editor/social/icon-styles/PngStyleIcon.vue';
import SquareStyleIcon from '@assets/icons/modules/communication/edm-editor/social/icon-styles/SquareStyleIcon.vue';
import SquareWithBrStyleIcon from '@assets/icons/modules/communication/edm-editor/social/icon-styles/SquareWithBrStyleIcon.vue';
import TextStyleIcon from '@assets/icons/modules/communication/edm-editor/social/icon-styles/TextStyleIcon.vue';

export const layoutTypeList = [
  {
    key: 'content-width',
    label: 'Content Width',
    value: '600',
  },
  {
    key: 'full-width',
    label: 'Full Width',
    value: '100%',
  },
];

export const alignList = [
  {
    key: 'align-start',
    icon: markRaw(AlignStartIcon),
    value: 'start',
  },
  {
    key: 'align-center',
    icon: markRaw(AlignCenterIcon),
    value: 'center',
  },
  {
    key: 'align-end',
    icon: markRaw(AlignEndIcon),
    value: 'end',
  },
];

export const justifyList = [
  {
    key: 'justify-start',
    icon: markRaw(JustifyStartIcon),
    value: 'start',
  },
  {
    key: 'justify-center',
    icon: markRaw(JustifyCenterIcon),
    value: 'center',
  },
  {
    key: 'justify-end',
    icon: markRaw(JustifyEndIcon),
    value: 'end',
  },
];

export const borderStyleList = [
  {
    key: 'border-solid',
    icon: markRaw(SolidLineIcon),
    value: 'solid',
  },
  {
    key: 'border-dotted',
    icon: markRaw(DottedLineIcon),
    value: 'dotted',
  },
  {
    key: 'border-dashed',
    icon: markRaw(DashedLineIcon),
    value: 'dashed',
  },
];

export const fontFamilyList = [
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Courier New',
    value: 'Courier New',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman',
  },
  {
    label: 'Trubuchet MS',
    value: 'Trubuchet MS',
  },
  {
    label: 'Verdana',
    value: 'Verdana',
  },
];

export const fontSizeList = [
  {
    label: '8px',
    value: 8,
  },
  {
    label: '9px',
    value: 9,
  },
  {
    label: '10px',
    value: 10,
  },
  {
    label: '11px',
    value: 11,
  },
  {
    label: '12px',
    value: 12,
  },
  {
    label: '13px',
    value: 13,
  },
  {
    label: '14px',
    value: 14,
  },
  {
    label: '15px',
    value: 15,
  },
  {
    label: '16px',
    value: 16,
  },
  {
    label: '18px',
    value: 18,
  },
  {
    label: '20px',
    value: 20,
  },
  {
    label: '22px',
    value: 22,
  },
  {
    label: '24px',
    value: 24,
  },
  {
    label: '28px',
    value: 28,
  },
  {
    label: '30px',
    value: 30,
  },
  {
    label: '36px',
    value: 36,
  },
  {
    label: '48px',
    value: 48,
  },
  {
    label: '60px',
    value: 60,
  },
  {
    label: '72px',
    value: 72,
  },
];

export const showDisplayList = [
  {
    key: 'icon-and-text',
    label: 'Icon & Text',
    value: 'icon-and-text',
  },
  {
    key: 'icon-only',
    label: 'Icon Only',
    value: 'icon-only',
  },
  {
    key: 'text-only',
    label: 'Text Only',
    value: 'text-only',
  },
];

export const textPositionList = [
  {
    label: 'Top',
    value: 'top',
  },
  {
    label: 'Under',
    value: 'bottom',
  },
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
];

export const fontCustomList = [
  {
    key: 'bold',
    value: 'bold',
  },
  {
    key: 'italic',
    value: 'italic',
  },
  {
    key: 'underline',
    value: 'underline',
  },
];

export const iconStylesList = [
  {
    key: 'circle-primary',
    value: 'border-radius:50%;background-color:#307ED1;',
    color: '#307ED1',
    icon: markRaw(CircleStyleIcon),
  },
  {
    key: 'circle-black',
    value: 'border-radius:50%;background-color:#000;',
    color: '#000',
    icon: markRaw(CircleStyleIcon),
  },
  {
    key: 'circle-grey',
    value: 'border-radius:50%;background-color:#939599;',
    color: '#939599',
    icon: markRaw(CircleStyleIcon),
  },
  {
    key: 'png',
    value: 'background-color:transparent;',
    icon: markRaw(PngStyleIcon),
  },
  {
    key: 'text-black',
    value: 'fill:#000;',
    color: '#000',
    icon: markRaw(TextStyleIcon),
  },
  {
    key: 'text-grey',
    value: 'fill:#939599;',
    color: '#939599',
    icon: markRaw(TextStyleIcon),
  },
  {
    key: 'square-primary',
    value: 'fill:#307ED1;',
    color: '#307ED1',
    icon: markRaw(SquareStyleIcon),
  },
  {
    key: 'square-black',
    value: 'background-color:#000;',
    color: '#000',
    icon: markRaw(SquareStyleIcon),
  },
  {
    key: 'square-grey',
    value: 'background-color:#939599;',
    color: '#939599',
    icon: markRaw(SquareStyleIcon),
  },
  {
    key: 'square-br-primary',
    value: 'background-color:#307ED1; border-radius: 5px;',
    color: '#307ED1',
    icon: markRaw(SquareWithBrStyleIcon),
  },
  {
    key: 'square-br-black',
    value: 'background-color:#000; border-radius: 5px;',
    color: '#000',
    icon: markRaw(SquareWithBrStyleIcon),
  },
  {
    key: 'square-br-grey',
    value: 'background-color:#939599; border-radius: 5px;',
    color: '#939599',
    icon: markRaw(SquareWithBrStyleIcon),
  },
];

export const borderSizeList = [
  {
    label: '0.1 px',
    value: '0.1',
  },
  {
    label: '0.5 px',
    value: '0.5',
  },
  {
    label: '1 px',
    value: '1',
  },
  {
    label: '1.5 px',
    value: '1.5',
  },
  {
    label: '2 px',
    value: '2',
  },
  {
    label: '2.5 px',
    value: '2.5',
  },
  {
    label: '3 px',
    value: '3',
  },
  {
    label: '4 px',
    value: '4',
  },
  {
    label: '5 px',
    value: '5',
  },
  {
    label: '6 px',
    value: '6',
  },
];

export const videoType = [
  {
    key: 'link',
    label: 'URL Link',
    value: 1,
  },
  {
    key: 'upload-file',
    label: 'Upload File',
    value: 2,
  },
];

export const openLinkTypeList = [
  {
    key: 'new-window',
    title: 'Open Link in New Window',
    value: 1,
  },
];

export const constantSocialList = [
  {
    key: 'web-site',
    label: 'Website',
  },
  {
    key: 'facebook',
    label: 'Facebook',
  },
  {
    key: 'instagram',
    label: 'Instagram',
  },
  {
    key: 'tik-tok',
    label: 'Tiktok',
  },
  {
    key: 'message',
    label: 'Message',
  },
  {
    key: 'linked-in',
    label: 'Linkedin',
  },
  {
    key: 'custom',
    label: 'Custom Icon',
  },
];

export const actionIconList = {
  editIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="11.925" height="13.203" viewBox="0 0 11.925 13.203">
  <g id="Group_642" data-name="Group 642" transform="translate(0 0)">
    <g id="Group_640" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 8.33, 0)">
      <path id="Union_33" data-name="Union 33" d="M0,8.352H3.211L1.606,10.28Zm0,0V0H3.211V8.352Z" transform="translate(0 2.417)" />
      <g id="Group_444" data-name="Group 444" transform="translate(0 0)">
        <rect id="Rectangle_280" data-name="Rectangle 280" width="3.211" height="1.611" rx="0.806" transform="translate(0 0)" />
        <rect id="Rectangle_281" data-name="Rectangle 281" width="3.211" height="0.806" transform="translate(0 0.806)"/>
      </g>
    </g>
    <rect id="Rectangle_440" data-name="Rectangle 440" width="11.924" height="1.325" rx="0.662" transform="translate(0 11.878)"/>
  </g>
</svg>
`,
  duplicateIcon: `<svg id="Group_44687" data-name="Group 44687" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.585" height="15.04" viewBox="0 0 12.585 15.04">
<defs>
</defs>
<path id="Subtraction_11" data-name="Subtraction 11" d="M7.72,11.626H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H7.72a1,1,0,0,1,1,1v9.626A1,1,0,0,1,7.72,11.626ZM1.454,1.454v8.719H7.267V1.454Z" transform="translate(0 3.414)" />
<g id="Group_516" data-name="Group 516" transform="translate(2.907)">
  <g id="Mask_Group_12" data-name="Mask Group 12" >
    <path id="Subtraction_12" data-name="Subtraction 12" d="M7.72,11.626H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H7.72a1,1,0,0,1,1,1v9.626A1,1,0,0,1,7.72,11.626ZM1.454,1.454v8.719H7.267V1.454Z" transform="translate(0 0.769)" />
  </g>
  <rect id="Rectangle_410" data-name="Rectangle 410" width="1.453" height="2.18" rx="0.727" transform="translate(0 2.222)" />
  <rect id="Rectangle_411" data-name="Rectangle 411" width="2.18" height="1.453" rx="0.727" transform="translate(4.36 10.942)"/>
</g>
</svg>
`,
  deleteIcon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11.476" height="13.249" viewBox="0 0 11.476 13.249">
<defs>
</defs>
<g id="Group_641" data-name="Group 641" >
  <path id="Path_316" data-name="Path 316" d="M3.942,1.429c0-.146,0-.256,0-.365A1.091,1.091,0,0,1,5.033,0c.45-.007.9,0,1.351,0A1.091,1.091,0,0,1,7.55,1.165c0,.075.007.15.012.252.1,0,.188.012.278.012.816,0,1.631,0,2.447,0a1.092,1.092,0,0,1,1.188,1.189,2.148,2.148,0,0,1-.054.579.963.963,0,0,1-.777.726c-.225.041-.277.134-.293.344q-.283,3.825-.584,7.648a1.306,1.306,0,0,1-1.447,1.334q-2.587,0-5.174,0A1.3,1.3,0,0,1,1.73,11.94q-.3-3.862-.6-7.724c-.006-.075-.015-.15-.02-.193a3.967,3.967,0,0,1-.629-.261A1.368,1.368,0,0,1,.073,2.194,1.065,1.065,0,0,1,1.18,1.429q1.223,0,2.447,0h.315M1.889,3.98c0,.107,0,.183,0,.259q.29,3.823.584,7.646c.035.446.224.61.677.611H8.3c.529,0,.689-.155.73-.69q.249-3.251.5-6.5c.034-.436.064-.873.1-1.323Zm3.848-1.8c-1.5,0-3.005,0-4.508.008a.546.546,0,0,0-.406.177.529.529,0,0,0,.479.847h8.863a.853.853,0,0,0,.253-.008c.091-.03.235-.086.244-.148a1.9,1.9,0,0,0,.014-.692c-.047-.2-.274-.184-.456-.184q-2.241,0-4.483,0m1.037-.763c.08-.546-.02-.664-.543-.664-.339,0-.679.019-1.015,0-.446-.032-.609.209-.479.669Z" transform="translate(0 0)" />
  <path id="Path_316_-_Outline" data-name="Path 316 - Outline" d="M5.647,0c.246,0,.491,0,.737,0A1.091,1.091,0,0,1,7.55,1.165c0,.075.007.15.012.252.1,0,.188.012.278.012h2.447a1.092,1.092,0,0,1,1.188,1.189,2.149,2.149,0,0,1-.054.579.963.963,0,0,1-.777.726c-.225.041-.277.134-.293.344q-.283,3.825-.584,7.648a1.306,1.306,0,0,1-1.447,1.334q-2.587,0-5.174,0A1.3,1.3,0,0,1,1.73,11.94q-.3-3.862-.6-7.724c-.006-.075-.015-.15-.02-.193a3.967,3.967,0,0,1-.629-.261A1.368,1.368,0,0,1,.073,2.194,1.065,1.065,0,0,1,1.18,1.429q1.193,0,2.387,0h.375c0-.146,0-.256,0-.365A1.091,1.091,0,0,1,5.033,0Q5.34,0,5.647,0ZM5.6.762c-.13,0-.259,0-.388-.012-.446-.032-.609.209-.479.669H6.773c.08-.546-.02-.664-.543-.664C6.022.755,5.813.762,5.6.762Zm4.708,2.457a.343.343,0,0,0,.106-.014c.091-.03.235-.086.244-.148a1.9,1.9,0,0,0,.014-.692c-.047-.2-.274-.184-.456-.184q-3.207,0-6.413,0-1.289,0-2.577.009a.546.546,0,0,0-.406.177.529.529,0,0,0,.479.847h8.863C10.213,3.214,10.264,3.219,10.312,3.219ZM5.724,12.5H8.3c.529,0,.689-.155.73-.69q.249-3.251.5-6.5c.034-.436.064-.873.1-1.323H1.889c0,.107-.005.183,0,.259q.29,3.823.584,7.646c.035.446.224.61.677.611Z" transform="translate(0 0)" />
  <path id="Path_319" data-name="Path 319" d="M142.269,108.5q0,1.171,0,2.343a1.187,1.187,0,0,1-.012.254.368.368,0,0,1-.716.016.966.966,0,0,1-.019-.253q0-2.381,0-4.762c0-.034,0-.068,0-.1a.378.378,0,0,1,.379-.394.371.371,0,0,1,.367.4c.007.288,0,.577,0,.866q0,.815,0,1.63" transform="translate(-134.157 -100.22)" />
  <path id="Path_319_-_Outline" data-name="Path 319 - Outline" d="M141.891,111.641a.591.591,0,0,1-.593-.458,1.038,1.038,0,0,1-.028-.289v-.03q0-2.127,0-4.254v-.53c0-.03,0-.064,0-.1a.626.626,0,0,1,.625-.624h.006a.618.618,0,0,1,.615.646c.005.206,0,.414,0,.615,0,.086,0,.171,0,.257q0,.815,0,1.63v.761q0,.791,0,1.582c0,.016,0,.033,0,.049a1.082,1.082,0,0,1-.018.258.591.591,0,0,1-.571.486Zm.007-5.786c-.033,0-.115,0-.128.164,0,.015,0,.036,0,.057v.533q0,2.127,0,4.253v.032a.675.675,0,0,0,.01.154c.022.078.052.091.111.091h.014c.06,0,.09-.017.107-.1a.7.7,0,0,0,.006-.142c0-.019,0-.038,0-.057q0-.791,0-1.581V108.5q0-.815,0-1.63,0-.13,0-.26c0-.2,0-.4,0-.6C142.013,105.856,141.927,105.855,141.9,105.855Z" transform="translate(-134.157 -100.22)"/>
  <path id="Path_534" data-name="Path 534" d="M142.269,108.5q0,1.171,0,2.343a1.187,1.187,0,0,1-.012.254.368.368,0,0,1-.716.016.966.966,0,0,1-.019-.253q0-2.381,0-4.762c0-.034,0-.068,0-.1a.378.378,0,0,1,.379-.394.371.371,0,0,1,.367.4c.007.288,0,.577,0,.866q0,.815,0,1.63" transform="translate(-136.157 -100.22)" />
  <path id="Path_534_-_Outline" data-name="Path 534 - Outline" d="M141.891,111.641a.591.591,0,0,1-.593-.458,1.038,1.038,0,0,1-.028-.289v-.03q0-2.127,0-4.254v-.53c0-.03,0-.064,0-.1a.626.626,0,0,1,.625-.624h.006a.618.618,0,0,1,.615.646c.005.206,0,.414,0,.615,0,.086,0,.171,0,.257q0,.815,0,1.63v.761q0,.791,0,1.582c0,.016,0,.033,0,.049a1.082,1.082,0,0,1-.018.258.591.591,0,0,1-.571.486Zm.007-5.786c-.033,0-.115,0-.128.164,0,.015,0,.036,0,.057v.533q0,2.127,0,4.253v.032a.675.675,0,0,0,.01.154c.022.078.052.091.111.091h.014c.06,0,.09-.017.107-.1a.7.7,0,0,0,.006-.142c0-.019,0-.038,0-.057q0-.791,0-1.581V108.5q0-.815,0-1.63,0-.13,0-.26c0-.2,0-.4,0-.6C142.013,105.856,141.927,105.855,141.9,105.855Z" transform="translate(-136.157 -100.22)" />
  <path id="Path_535" data-name="Path 535" d="M142.269,108.5q0,1.171,0,2.343a1.187,1.187,0,0,1-.012.254.368.368,0,0,1-.716.016.966.966,0,0,1-.019-.253q0-2.381,0-4.762c0-.034,0-.068,0-.1a.378.378,0,0,1,.379-.394.371.371,0,0,1,.367.4c.007.288,0,.577,0,.866q0,.815,0,1.63" transform="translate(-138.157 -100.22)"/>
  <path id="Path_535_-_Outline" data-name="Path 535 - Outline" d="M141.891,111.641a.591.591,0,0,1-.593-.458,1.038,1.038,0,0,1-.028-.289v-.03q0-2.127,0-4.254v-.53c0-.03,0-.064,0-.1a.626.626,0,0,1,.625-.624h.006a.618.618,0,0,1,.615.646c.005.206,0,.414,0,.615,0,.086,0,.171,0,.257q0,.815,0,1.63v.761q0,.791,0,1.582c0,.016,0,.033,0,.049a1.082,1.082,0,0,1-.018.258.591.591,0,0,1-.571.486Zm.007-5.786c-.033,0-.115,0-.128.164,0,.015,0,.036,0,.057v.533q0,2.127,0,4.253v.032a.675.675,0,0,0,.01.154c.022.078.052.091.111.091h.014c.06,0,.09-.017.107-.1a.7.7,0,0,0,.006-.142c0-.019,0-.038,0-.057q0-.791,0-1.581V108.5q0-.815,0-1.63,0-.13,0-.26c0-.2,0-.4,0-.6C142.013,105.856,141.927,105.855,141.9,105.855Z" transform="translate(-138.157 -100.22)" />
</g>
</svg>
`,
};
