<template>
  <svg
    id="Group_34458"
    data-name="Group 34458"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16.501"
    height="19.051"
    viewBox="0 0 16.501 19.051"
  >
    <defs>
      <!-- <clipPath id="clip-pathc">
        <rect id="Rectangle_439" data-name="Rectangle 439" class="cls-1-delete" width="16.501" height="19.051" />
      </clipPath> -->
    </defs>
    <g id="Group_1315" data-name="Group 1315">
      <g id="Group_641" data-name="Group 641">
        <path
          id="Path_316"
          data-name="Path 316"
          class="cls-1-delete"
          d="M5.668,2.055c0-.21-.006-.367,0-.524A1.569,1.569,0,0,1,7.237.006C7.884,0,8.532,0,9.179,0a1.568,1.568,0,0,1,1.677,1.67c0,.108.011.216.018.362.141.006.27.017.4.017,1.173,0,2.345,0,3.518,0A1.57,1.57,0,0,1,16.5,3.765a3.089,3.089,0,0,1-.077.832,1.384,1.384,0,0,1-1.117,1.043c-.324.059-.4.193-.421.494q-.407,5.5-.84,11a1.877,1.877,0,0,1-2.081,1.918q-3.72,0-7.439,0a1.873,1.873,0,0,1-2.038-1.882q-.43-5.553-.859-11.106c-.008-.108-.022-.215-.029-.278a5.7,5.7,0,0,1-.9-.375A1.966,1.966,0,0,1,.1,3.155,1.531,1.531,0,0,1,1.7,2.055q1.759,0,3.518,0h.453M2.716,5.723c0,.154-.007.263,0,.372q.417,5.5.839,10.995c.05.641.323.878.973.878q3.7,0,7.4,0c.761,0,.99-.223,1.049-.993q.358-4.675.72-9.35c.048-.627.091-1.255.138-1.9ZM8.249,3.138c-2.161,0-4.321-.007-6.482.011a.786.786,0,0,0-.583.254.761.761,0,0,0,.688,1.218H14.616a1.226,1.226,0,0,0,.364-.012c.131-.042.337-.124.35-.213a2.727,2.727,0,0,0,.02-1c-.068-.29-.394-.265-.656-.265q-3.223,0-6.445,0m1.491-1.1c.116-.785-.029-.955-.78-.955-.487,0-.976.028-1.46-.007-.641-.046-.876.3-.689.962Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          class="cls-1-delete"
          d="M69.137,109.7c0-1.172,0-2.344,0-3.516a.576.576,0,0,1,.335-.6.518.518,0,0,1,.717.359,1.517,1.517,0,0,1,.029.363q0,3.406,0,6.812c0,.073,0,.147,0,.22a.538.538,0,0,1-.546.527.524.524,0,0,1-.526-.543c-.013-.586,0-1.172-.005-1.758q0-.934,0-1.868Z"
          transform="translate(-64.065 -97.8)"
        />
        <path
          id="Path_318"
          data-name="Path 318"
          class="cls-1-delete"
          d="M105.137,109.6c0-1.172,0-2.344,0-3.516a.576.576,0,0,1,.335-.6.518.518,0,0,1,.717.359,1.52,1.52,0,0,1,.029.363q0,3.406,0,6.812c0,.073,0,.147,0,.22a.538.538,0,0,1-.546.527.524.524,0,0,1-.526-.543c-.013-.586,0-1.172-.005-1.758q0-.934,0-1.868Z"
          transform="translate(-97.425 -97.712)"
        />
        <path
          id="Path_319"
          data-name="Path 319"
          class="cls-1-delete"
          d="M142.6,109.772q0,1.684,0,3.369a1.708,1.708,0,0,1-.017.365.53.53,0,0,1-1.03.023,1.39,1.39,0,0,1-.027-.363q0-3.424,0-6.847c0-.049,0-.1,0-.146a.543.543,0,0,1,.546-.567.533.533,0,0,1,.527.579c.01.415,0,.83,0,1.245q0,1.172,0,2.344"
          transform="translate(-131.143 -97.862)"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1-delete {
  fill: #606a6c;
  stroke: #606a6d;
  stroke-width: 0.5px;
}
</style>
