import { ref, Ref, reactive, watch, markRaw, onMounted, onBeforeUnmount, toRaw } from 'vue';
import router from '@/router';
import { saveAs } from 'file-saver';
import api from '@services/api';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import segmentExportReport from '@models/segment/exportReport';
interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}
interface SegmentDetailOrdering {
  key: string;
  direction: string;
}
export default function useSegmentExportReport(
  pagination: BaseTable.Pagination,
  filtersObj: any,
  textSearch: string,
  selectViewMode: SegmentViewmode,
  sortModal?: SegmentDetailOrdering,
) {
  const { fetchSegmentExportReport } = segmentExportReport();
  const { isLoading } = useLoading();
  const { openDefaultErrorModal } = useValidationModal();
  const fetchReportSement = () => {
    isLoading(true);
    fetchSegmentExportReport.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentExportReport.payload.Select_flag = 2; //1 => select for export , 2 => Export all  for this phase use 2 only
    fetchSegmentExportReport.payload.Limit = 1000000;
    fetchSegmentExportReport.payload.Page = pagination.currentPage;
    fetchSegmentExportReport.payload.Filters = filtersObj.value;
    fetchSegmentExportReport.payload.Search_Value = textSearch;
    fetchSegmentExportReport.payload.ResultType = selectViewMode.selectedOption.type;
    fetchSegmentExportReport.payload.View_Select_Main = selectViewMode.selectedOption.mode;

    if (sortModal) {
      if (sortModal.direction) {
        fetchSegmentExportReport.payload.Ordering = [
          {
            Seq: 1,
            Key: sortModal.key,
            Direction: sortModal.direction,
          },
        ];
      }
    } else {
      fetchSegmentExportReport.payload.Ordering = [];
    }
    api
      .apiRequest(fetchSegmentExportReport)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response,
          `Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
      });
  };
  return {
    fetchReportSement,
  };
}
