import permissionModel from '@models/permission/permission';
import apiService from '@services/api';

class PermissionService {
  async fetchPermissions(): Promise<Permission.Response.Key[]> {
    const { fetchPermissionKeyInfo } = permissionModel();
    try {
      const resp = await apiService.apiRequest(fetchPermissionKeyInfo);
      return resp.data.key_lists as Permission.Response.Key[];
    } catch (error) {
      // Handle or rethrow the error as needed
      throw error;
    }
  }
}

const permissionService = new PermissionService();
export default permissionService;
