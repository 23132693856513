<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45" height="45" viewBox="0 0 45 45">
    <defs>
      <!-- <clipPath id="clip-path-11">
        <rect id="Rectangle_51049" data-name="Rectangle 51049" width="45" height="45" transform="translate(-1.102 -6.515)" fill="none" />
      </clipPath> -->
      <filter id="Union_239-24" x="-22.669" y="-24.621" width="98.451" height="98.482" filterUnits="userSpaceOnUse">
        <feOffset dx="-5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur" />
        <feFlood flood-opacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-25" x="-22.669" y="-24.621" width="98.451" height="98.482" filterUnits="userSpaceOnUse">
        <feOffset dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur-2" />
        <feFlood flood-color="#ffd783" result="color" />
        <feComposite operator="out" in="SourceGraphic" in2="blur-2" />
        <feComposite operator="in" in="color" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter id="Rectangle_48635" x="-7.115" y="-9.486" width="77.729" height="83.87" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-3" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_4105" x="-7.115" y="0.476" width="77.729" height="77.729" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-4" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-26" x="-17.42" y="4.572" width="108.582" height="108.582" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-5" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-27" x="-51.398" y="-2.197" width="107.176" height="107.176" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-6" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-28" x="-35.978" y="-20.985" width="109.774" height="109.774" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-7" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <!-- <g id="Mask_Group_98837" data-name="Mask Group 98837" transform="translate(1.102 6.515)" clip-path="url(#clip-path-11)"> -->
    <g id="Mask_Group_98837" data-name="Mask Group 98837" transform="translate(1.102 6.515)">
      <g id="Group_115637" data-name="Group 115637">
        <g id="Group_110110" data-name="Group 110110" transform="translate(6.885 1.365)">
          <g id="Group_110106" data-name="Group 110106" transform="translate(0 0)">
            <g id="Group_110104" data-name="Group 110104" transform="translate(0 8.187)">
              <path
                id="Union_239-29"
                data-name="Union 239"
                d="M.413.936C1.878,1.676,3.026,2.328,3.8,2.793A10.716,10.716,0,1,0,10.844.156h-.113C10.119.127,7.369,0,4.806,0,1.728,0-1.08.183.413.936Z"
                transform="translate(21.561 21.589) rotate(180)"
                fill="#fff"
              />
            </g>
            <g id="Group_110105" data-name="Group 110105" transform="translate(11.845 0)">
              <g data-type="innerShadowGroup">
                <g transform="matrix(1, 0, 0, 1, -19.83, -7.88)" filter="url(#Union_239-24)">
                  <path
                    id="Union_239-30"
                    data-name="Union 239"
                    d="M.449,22.463c1.594-.8,2.843-1.514,3.688-2.02A11.656,11.656,0,1,1,11.8,23.312h-.123c-.666.032-3.657.17-6.445.17C1.879,23.482-1.175,23.283.449,22.463Z"
                    transform="translate(19.83 7.88)"
                    fill="#ffc503"
                  />
                </g>
                <g transform="matrix(1, 0, 0, 1, -19.83, -7.88)" filter="url(#Union_239-25)">
                  <path
                    id="Union_239-31"
                    data-name="Union 239"
                    d="M.449,22.463c1.594-.8,2.843-1.514,3.688-2.02A11.656,11.656,0,1,1,11.8,23.312h-.123c-.666.032-3.657.17-6.445.17C1.879,23.482-1.175,23.283.449,22.463Z"
                    transform="translate(19.83 7.88)"
                    fill="#ffc606"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_110109" data-name="Group 110109" transform="translate(22.399 5.135)">
            <g transform="matrix(1, 0, 0, 1, -30.39, -13.01)" filter="url(#Rectangle_48635)">
              <rect id="Rectangle_48635-2" data-name="Rectangle 48635" width="2.729" height="8.87" rx="1.365" transform="translate(30.39 13.01)" />
            </g>
            <g transform="matrix(1, 0, 0, 1, -30.39, -13.01)" filter="url(#Ellipse_4105)">
              <ellipse id="Ellipse_4105-2" data-name="Ellipse 4105" cx="1.365" cy="1.365" rx="1.365" ry="1.365" transform="translate(30.39 22.98)" />
            </g>
          </g>
        </g>
        <g id="Group_115389" data-name="Group 115389" transform="translate(2.176 18.789) rotate(90)">
          <g transform="matrix(0, -1, 1, 0, -25.3, 3.28)" filter="url(#Union_239-27)">
            <path
              id="Union_239-10"
              data-name="Union 239"
              d="M1.088,0A1.088,1.088,0,1,1,0,1.088,1.088,1.088,0,0,1,1.088,0Z"
              transform="translate(1.1 27.48) rotate(-90)"
              fill="#ffc606"
            />
          </g>
        </g>
        <g id="Group_115390" data-name="Group 115390" transform="translate(15.42)">
          <g transform="matrix(1, 0, 0, 1, -16.52, -6.51)" filter="url(#Union_239-28)">
            <path
              id="Union_239-11"
              data-name="Union 239"
              d="M2.387,0A2.387,2.387,0,1,1,0,2.387,2.387,2.387,0,0,1,2.387,0Z"
              transform="translate(21.3 11.29) rotate(180)"
              fill="#ffc606"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
