<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.614" height="40.608" viewBox="0 0 40.614 40.608">
    <defs>
      <!-- <clipPath id="clip-path321">
        <rect id="Rectangle_14701" data-name="Rectangle 14701" width="40.614" height="40.608" transform="translate(0 0)" />
      </clipPath> -->
    </defs>
    <g id="Group_37773" data-name="Group 37773" transform="translate(-1020.193 -425.196)">
      <g id="Group_37772" data-name="Group 37772" transform="translate(1020.193 425.195)">
        <!-- <g id="Group_33425" data-name="Group 33425" clip-path="url(#clip-path321)"> -->
        <g id="Group_33425" data-name="Group 33425">
          <path
            id="Path_22466"
            data-name="Path 22466"
            d="M20.306,40.608a20.3,20.3,0,1,1,14.361-5.947,20.3,20.3,0,0,1-14.361,5.947m0-36.547A16.214,16.214,0,1,0,31.8,8.818,16.244,16.244,0,0,0,20.306,4.061Z"
          />
        </g>
      </g>
      <g id="Group_37773-2" data-name="Group 37773" transform="translate(657.919 36.082)">
        <path
          id="Path_24177"
          data-name="Path 24177"
          d="M378.84,404.03a.78.78,0,0,0,.78-.78V400.7a.78.78,0,0,0-1.56,0v2.552A.78.78,0,0,0,378.84,404.03Z"
        />
        <path
          id="Path_24178"
          data-name="Path 24178"
          d="M386.322,404.03a.78.78,0,0,0,.78-.78V400.7a.779.779,0,1,0-1.559,0v2.552A.78.78,0,0,0,386.322,404.03Z"
        />
        <path
          id="Path_24179"
          data-name="Path 24179"
          d="M392.081,409.365v-4.529a3,3,0,0,0-3-3H388v1.414a1.68,1.68,0,1,1-3.359,0v-1.414h-4.124v1.414a1.68,1.68,0,1,1-3.359,0v-1.414h-1.079a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h12.665a5.323,5.323,0,0,1-.959-.1,5.323,5.323,0,0,0,.959.1h.335a2.988,2.988,0,0,0,1.194-.25h0a5.288,5.288,0,0,0,1.806-9.221Zm-13.427,6.777a.5.5,0,0,1-.5.5H375.2a.5.5,0,0,1-.5-.5v-2.951a.5.5,0,0,1,.5-.5h2.951a.5.5,0,0,1,.5.5Zm0-5.506a.5.5,0,0,1-.5.5H375.2a.5.5,0,0,1-.5-.5v-2.951a.5.5,0,0,1,.5-.5h2.951a.5.5,0,0,1,.5.5Zm7.854-1.967v-.984a.5.5,0,0,1,.5-.5h2.951a.5.5,0,0,1,.5.5v.783a5.114,5.114,0,0,0-4.512.506A5.415,5.415,0,0,1,386.508,408.669Zm-5.9-.984a.5.5,0,0,1,.5-.5h2.951a.5.5,0,0,1,.5.5v2.547h0a5.29,5.29,0,0,0-.572.9h-2.879a.5.5,0,0,1-.5-.5Zm3.451,8.957h-2.951a.5.5,0,0,1-.5-.5v-2.951a.5.5,0,0,1,.5-.5h2.384a5.371,5.371,0,0,0-.081.808,5.291,5.291,0,0,0,.952,3.029A.491.491,0,0,1,384.057,416.642Zm4.689.094a3.238,3.238,0,1,1,3.238-3.237A3.241,3.241,0,0,1,388.746,416.736Z"
        />
        <path id="Path_24180" data-name="Path 24180" d="M389.149,411.054h-1.084v3.357h2.418v-1.084h-1.334Z" />
      </g>
    </g>
  </svg>
</template>
