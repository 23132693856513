import { ref, Ref, toRaw } from 'vue';
import router from '@/router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';
import { useModuleStore } from '@/store/moduleStore';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: AllDataSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: AllDataSegment.Datas[];
}

interface Props {
  selectedSource: Segment.Datasource;
}

interface FolderList {
  segmentName: string;
  folderSelect: {
    folderId: number;
    label: string;
  };
}

export default function(
  segmentId: number,
  applySegmentId: number,
  applyDestSegmentId: number,
  folderId: number,
  props: Props,
  isSelectedAll: Ref<boolean>,
  includeOrExcludeList: Ref<Record<string, any>[]>,
  dataSourceDisplaySave: Ref<AllDataSegment.DatasourceDisplay>,
  totalRecord: Ref<number>,
) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal, openWarningModal } = useValidationModal();
  const { fetchSegmentSaveModel, fetchCancelSegmentModel } = segmentResultModel();
  const type: Ref<number> = ref(0);
  const idFolder: Ref<number> = ref(0);
  const nameSegment: Ref<string> = ref('');
  const nameFolder: Ref<string> = ref('');
  const idSegment: Ref<number> = ref(segmentId);
  const isLoadingActive: Ref<boolean> = ref(false);
  const isCompleted: Ref<boolean> = ref(false);
  const isOpenModalRename: Ref<boolean> = ref(false);

  const onClickSave = (value: FolderList) => {
    type.value = 3;
    nameSegment.value = value.segmentName;
    idFolder.value = value.folderSelect.folderId;
    nameFolder.value = value.folderSelect.label;

    onLoadDataSave();
  };

  const onOpenEditname = () => {
    isOpenModalRename.value = false;
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplaySave: AllDataSegment.DatasourceDisplay): AllDataSegment.SaveColumns[] => {
      const saveColumns: AllDataSegment.SaveColumns[] = [];
      dataSourceDisplaySave.columns.forEach((element) => {
        const obj: AllDataSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const mapData = (includeOrExcludeList: Record<string, any>[]): AllDataSegment.Datas[] => {
      const datas: AllDataSegment.Datas[] = [];
      includeOrExcludeList.forEach((element) => {
        const dataMap: AllDataSegment.Data_Map[] = [];
        sourceDisplay.columns.forEach((el: AllDataSegment.DatasourceDisplayColumns) => {
          const obj: AllDataSegment.Data_Map = {
            ColumnId: el.column_id,
            Value: element[el.key],
          };
          dataMap.push(obj);
        });
        datas.push({ Data_Map: dataMap });
      });
      return datas;
    };

    const listIncludeOrExclude: Record<string, any>[] = toRaw(includeOrExcludeList.value);
    const sourceDisplay: AllDataSegment.DatasourceDisplay = toRaw(dataSourceDisplaySave.value);

    const payload: SegmentSavePayload = {
      Save_Type: 1,
      Type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      Name: nameSegment.value,
      SegmentId: !router.currentRoute.value.query.segmentOldId ? segmentId || applySegmentId : Number(router.currentRoute.value.query.segmentOldId),
      Apply_SegmentId: applySegmentId,
      Dest_SegmentId: !router.currentRoute.value.query.segmentOldId ? applyDestSegmentId : Number(router.currentRoute.value.query.segmentOldId),
      Root_TableId: props.selectedSource.tableid,
      Root_FolderId: idFolder.value,
      Dest_FolderId: idFolder.value,
      saveColumns: type.value == 3 ? mapColumn(sourceDisplay) : [],
      select_flag: isSelectedAll.value ? 2 : 1,
      Datas: type.value == 3 ? mapData(listIncludeOrExclude) : [],
    };
    fetchSegmentResultList(payload);
  };

  const fetchSegmentResultList = (payload: SegmentSavePayload) => {
    let countSaveSegment = 0;

    if (includeOrExcludeList.value.length > 0 && isSelectedAll.value == true) {
      countSaveSegment = totalRecord.value - includeOrExcludeList.value.length;
    } else if (includeOrExcludeList.value.length == 0 && isSelectedAll.value == true) {
      countSaveSegment = totalRecord.value;
    } else {
      countSaveSegment = includeOrExcludeList.value.length;
    }

    openWarningModal(
      `บันทึกทั้งหมด ${countSaveSegment} รายการไปยัง \n “${nameSegment.value}” \n ในโฟลเดอร์ “${nameFolder.value}”`,
      'ต้องการบันทึกใช่ หรือไม่?',
      () => confirmSaveSegment(),
    );

    function confirmSaveSegment() {
      isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
      isLoadingActive.value = true;
      fetchSegmentSaveModel.payload = payload;

      api
        .apiRequest(fetchSegmentSaveModel)
        .then((response) => {
          if (response.status === 'success') {
            isCompleted.value = true;
            idSegment.value = response.data.segment_id;
          }
        })
        .catch((error) => {
          if (error.data.error.message == 'Name of Segment is duplicate') {
            isLoadingActive.value = false;
            isOpenModalRename.value = true;
          } else {
            openDefaultErrorModal(error);
          }
        })
        .finally(() => {
          isLoadingActive.value = false;
          isLoading(false);
          if (isCompleted.value) {
            openSuccessModal(
              'บันทึกรายการสำเร็จ',
              '',
              '',
              () => onDirectSegment(),
              () => onDirectSegment(),
            );
          }
        });
    }
  };

  const cancelSegmentPreview = () => {
    openWarningModal('ยกเลิก Preview Segment', `ต้องการลบ Preview Segment ${nameSegment.value} หรือไม่`, () => confirmSegment());

    function confirmSegment() {
      isLoading(true, 'กำลังยกเลิกรายการ กรุณารอสักครู่...');
      if (router.currentRoute.value.query.type) {
        fetchCancelSegmentModel.payload.segmentId = applyDestSegmentId;
      } else {
        fetchCancelSegmentModel.payload.segmentId = applySegmentId;
      }

      api
        .apiRequest(fetchCancelSegmentModel)
        .then((response) => {
          isLoading(false);
          if (response.status === 'success') {
            openSuccessModal(
              'ยกเลิกบันทึกรายการสำเร็จ',
              '',
              '',
              () => onDirectCancel(),
              () => onDirectCancel(),
            );
          }
          return response;
        })
        .catch((error) => {
          openDefaultErrorModal(error);
        });
    }
  };

  const onDirectSegment = () => {
    localStorage.setItem('listNewData', JSON.stringify(toRaw(includeOrExcludeList.value)));

    router.replace({
      path: '/segment/data-segment/segment-detail',
      query: {
        tab: router.currentRoute.value.query.tab,
        tableId: router.currentRoute.value.query.tableId,
        folderId: folderId || idFolder.value,
        folderName: router.currentRoute.value.query.folderName || nameFolder.value,
        segmentId: idSegment.value,
        segmentName: nameSegment.value,
        type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      },
    });
  };

  const onDirectCancel = async () => {
    const allModuleVersionStore = useModuleStore();

    if (router.currentRoute.value.query.folderId) {
      await router.replace({
        path: '/segment/data-segment/folder-inside',
        query: {
          folderId: folderId || idFolder.value,
          folderName: router.currentRoute.value.query.folderName || nameFolder.value,
          tableId: router.currentRoute.value.query.tableId,
        },
      });
    } else {
      if (allModuleVersionStore.getSegmentVersion == '1.0.0') {
        await router.replace({
          path: '/segment/data-segment/main',
          query: {
            tab: router.currentRoute.value.query.tab,
          },
        });
      } else {
        await router.replace({
          path: '/segmentv2',
          query: {
            tab: router.currentRoute.value.query.tab,
          },
        });
      }
    }
  };

  return {
    isLoadingActive,
    isOpenModalRename,
    onOpenEditname,
    nameSegment,
    onClickSave,
    cancelSegmentPreview,
  };
}
