export default {
  campaign: {
    line_oa: {
      create: {
        uploading: 'Uploading',
        error: 'Error',
        filenameExists: 'This filename already exists, please rename the file.',
        fileOverSize: 'File size exceeded.',
        wrongFileType: 'Wrong file type.',
        uploadImage: 'Upload Image',
        uploadVideo: 'Upload Video',
        uploadVoice: 'Upload Voice',
        actionToolTip: 'The action label is shown on unsupported devices and read by text-to-speech services.',
      },
    },
    sms: {},
  },
  resume_campaign: {
    campaign_list_paused: {
      button_back: 'Back',
      tab: {
        all_list_paused_tab: 'All Campaign',
        commu_list_paused_tab: 'Communication',
        mka_list_paused_tab: 'Marketing Automation',
      },
      box_detail: {
        channel: 'Channel',
        detail_channel: {
          all: 'All',
          communication: 'Communication',
          mka: 'Marketing Automation',
        },
        campaignUsing: 'Campaign in use',
        requiredCredit: 'Credit to be used',
      },
      next: 'Next',
      confirm_all_button: 'Start using all',
      confirm_button: 'Start using',
      confirm_count_button: 'list',
    },

    check_export_conditions: {
      title: 'Config resend condition',
      box_detail: {
        count_campaign_select: 'Campaign selected',
        requiredCredit: 'Credit to be used',
      },
      box_conditions: {
        title_time: 'Please select schedule.',
        detail_time: {
          time: 'Schedule a later',
          now: 'Send Now',
        },

        title_return: 'Please select resend mode.',
        detail_return: {
          send: { start: '', middle: '', end: 'Resend to pending recipients.' },
          unsend: { start: '', middle: '', end: "Don't resend to previous pending recipients." },
        },

        title_round: 'Please select resend to previous mode.',
        detail_round: {
          all_round: 'Resend to all previous.',
          latest_round: 'Resend to last previous round.',
          custom_round: 'Specify previous round to resend.',
        },
        manage_campaigns: 'Select',
      },
      confirm_button: 'Resend',
    },
  },
  //resume_campaign
  No: 'No.',
  All: 'All',
  communication: 'Communication',
  campaign_list_paused: 'Campaign list paused',
  mka: 'Marketing Automation',
  bi: 'Business Intelligent',
  ins: 'Customer Insight',
  dc: 'Data Connector',
  di: 'Data Ingestion',
  seg: 'Segment',
  perm: 'Permission',
  show: 'Show',
  list: 'List',
  CampaignName: 'Campaign Name',
  function: 'Function',
  module: 'Module',
  channel: 'Channel',
  schedule_mode: 'Schedule',
  count_dispatchable: 'Total Recipients',
  count_success: 'Success',
  count_pendingcredit: 'Pending',
  est_credit_use: 'Credit to be used',
  updated: 'Last Updated',
  status: 'Status',
  CampaignNameExpand: 'Template Name used in used by node',
  send_now: 'Send Now',
  schedule_later: 'Schedule a later',
  repeat: 'Repeat',
  pause: 'Pause',
  draft: 'Draft',
  complete: 'Complete',
  cancel: 'Cancel',
  dispath: 'Sending',
  scheduled: 'Scheduled', //Scheduled
  preparing: 'Preparing',
  resendInformation: 'Resend information',
  totalResending: 'Total Resending',
  round: 'Round',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  outstandingAmount: 'Outstanding amount',
  apply: 'apply',
  manageCampaignsInScheduledRepeat: 'Manage campaigns in scheduled repeat.',
  time: 'time',
  minute: '',
  sentEvery: 'Sent Every',

  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  End: 'End',
  completed_successfully: 'Completed successfully',
  error_occurred: 'An error occurred.',
  check_export_conditions: 'Credit to be used',
  confirm_YesOrNo: 'Confirm, Yes or No?',
  tooltip_column_accum_recp:
    'Count cumulatively, single-round Communications campaigns are counted first round only. Repeat Communications campaigns are counted cumulatively from first round  to the last run round. Marketing Automation campaigns are counted cumulatively according to the current number of recipients that are currently in the flow. (Count only recipients who is sent but received insufficient credit)',
  tooltip_column_accum_recp_pendingcredit: 'Sent but credit not enough',
  tooltip_column_est_credit_use: 'Estimated credit required',
  tooltip_datetimeMode: 'กำหนดเวลาการเริ่มทำงานอีกครั้งของแคมเปญ',
  tooltip_resendMode: 'Choose whether to resend messages to recipients who were about to send sent but did not have enough credit.',
  tooltip_resend: 'If selected, a message will be sent to pending recipient.',
  tooltip_DoNot_resend: "If selected, a message won't be sent to all previous pending recipient.",
  tooltip_resendPreviousMode:
    'Config resend to previous mode for Communication campaigns. If the campaign is Marketing Automation, will always use Resend to all previous.',
  tooltip_resend_allPrevious: 'Resend the past rounds that has insufficient credits.',
  tooltip_resend_lastPreviousRound: 'Resend the last previous round.',
  tooltip_resend_lastPreviousRoundRepeat:
    'ต้องการส่งย้อนไปยังผู้รอรับคงค้างเฉพาะรอบล่าสุด กรณีมีมากกว่า 1 รอบการส่งค้าง ตัวอย่างเช่น มีการตั้งส่งทุกวันที่จันทร์ รอบคงค้างเนื่องจากเครดิตหมด มีจำนวน 3 จันทร์ ต้องการส่งย้อนหลังไปยังผู้รับคงค้างเฉพาะผู้ที่ควรจะได้รับรอบวันจันทร์รอบล่าสุดที่มีการคงค้างเท่านั้น',
  tooltip_resend_specifyPrevious: 'Only for Communication campaigns with schedule type repeat.',
  sunday: 'Sunday',
  expand: 'expand',
  dayMonthYear: 'day/month/year',
  language: 'en-us',
  save_wait: 'Recording, please wait a moment...',
  successful: 'Successful',
  //Limitation
  Limit_buttonEdit: 'Edit',
  Limit_buttonSave: 'Save',
  Limit_amountLimit: 'Limited number of exports',
  Limit_amountLimit_detail:
    'Limit the number of messages users can send per day, month, and year per customer to maintain system security. and user account',
  Limit_step: 'step',
  Limit_stepOne_amountDetail: 'Please set the number to limit messages sent to one customer.',
  Limit_messagesPerDay: 'Limit messages per day',
  Limit_messagesPerMonth: 'Limit messages per month',
  Limit_messagesPerYear: 'Limit messages per year',
  Limit_noMoreThan: 'Limited to no more than',
  Limit_Unlimited_messagesPerDay: 'Unlimited messages sent per day',
  Limit_Unlimited_messagesPerMonth: 'Unlimited messages sent per month',
  Limit_Unlimited_messagesPerYear: 'Unlimited messages sent per year',
  Limit_Unlimited_exportPerDay: 'Unlimited sending per day',
  Limit_Unlimited_exportPerMonth: 'Unlimited sending per month',
  Limit_Unlimited_exportPerYear: 'Unlimited sending per year',
  Limit_messages: 'messages',
  Limit_stepTwo_detail: 'Set messaging limits',
  Limit_disallow_exceedLimit:
    "No retroactive exports are allowed if the number of exports exceeds the limit or falls within the system's restricted time period.", //Export prohibited If the number of sent messages exceeds the limit
  Limit_nextRound_exceedLimit: 'Next round of export If the number of sent messages exceeds the limit',

  Limit_timeLimit: 'Export time limit',
  Limit_timeLimit_detail: 'Limit the days and times of sending messages per customer to maintain system security. and user account',
  Limit_stepOne_timeDetail: 'Please select a method to limit the export time.',
  Limit_weekly: 'weekly',
  Limit_dateAndTime: 'Set date and time',
  Limit_LimitedDays: 'Limited days',
  Limit_LimitedExportTime: 'Limited export time',
  Limit_between: 'Between',
  Limit_to: 'to',
  Limit_noTimeLimit: 'No time limit',

  Limit_ResultOfMessage: 'Result of message restriction',
  Limit_sendingOfMessages_by: 'Restrict sending of messages by',
  Limit_cusReceive: 'Customers will receive',
  Limit_notMoreThan: 'not more than',
  Limit_day: 'day',
  Limit_month: 'month',
  Limit_year: 'year',

  Limit_tooltipButtonAdd: 'Add',
  Limit_tooltipButtonRemove: 'Remove',
  Limit_view_calendar: 'Day limit results and message sending time',

  Limit_ConfirmQuantityLimit: 'Confirm quantity limit',
  Limit_DateAndTimeOfExport: 'Date and time of export.',
  Limit_Confirm_yesOrNo: 'Confirm, yes or no?',

  Unconsent_PDPA_Modal: 'This brand does not enable the function to send messages to individuals without their consent',

  this_month: 'This Month',
  rv_title: 'Interactive Video',
  rv_show_button: 'Display',
  rv_hide_button: 'Hide',
  rv_show_button_tooltip: 'Display action button linking to URL',
  rv_hide_button_tooltip: 'Do not want to show action button after video ends',
  rv_detail: 'Action Button Details',
  rv_message_tag: 'Label',
  rv_file_recommend: 'Supported File formats',
  rv_size_file: 'File Size',
  rv_not_over: 'Not Over',
  rv_learn_more: 'Learn more',
  rv_install_app: 'Install app',
  rv_purchase: 'Purchase',
  rv_pre_register: 'Preregister',
  rv_enter: 'Enter',
  rv_apply: 'Apply',
  rv_join_event: 'Join event',
  rv_vote: 'Vote',
  rv_search_for_store: 'Search for store',
  rv_contact_us: 'Contact us',
  rv_request_materials: 'Request materials',
  rv_view_more_detail: 'View more videos',
  rv_custom: 'Custom',
};
