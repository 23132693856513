<template>
  <svg
    data-name="Group 31191"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="26.237"
    height="32.091"
    viewBox="0 0 26.237 32.091"
  >
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_13082" data-name="Rectangle 13082" width="11.069" height="11.624" fill="#07f" />
      </clipPath> -->
      <!-- <clipPath id="clip-path-2">
        <rect id="Rectangle_14055" data-name="Rectangle 14055" width="26.237" height="32.091" fill="#07f" />
      </clipPath> -->
    </defs>
    <g data-name="Group 31163" transform="translate(7.584 10.233)">
      <!-- <g data-name="Group 29238" clip-path="url(#clip-path)"> -->
      <g data-name="Group 29238">
        <path
          id="Path_9611"
          data-name="Path 9611"
          d="M5.307,0A5.789,5.789,0,0,0,1.46,1.411L.445.563A.272.272,0,0,0,0,.777L.084,4.683a.272.272,0,0,0,.306.264l3.99-.5a.272.272,0,0,0,.14-.478L3.073,2.757A3.756,3.756,0,1,1,2.3,8.124a.444.444,0,0,0-.583-.106l-1,.62a.443.443,0,0,0-.122.641A5.812,5.812,0,1,0,5.307,0"
          transform="translate(0 0.001)"
        />
      </g>
    </g>
    <g data-name="Group 31191" transform="translate(0 0)" clip-path="url(#clip-path-2)">
      <path
        id="Path_21102"
        data-name="Path 21102"
        d="M16.908,3.917,13.286.1a.324.324,0,0,0-.558.223V2.879A13.118,13.118,0,0,0,4.961,26.265,1.23,1.23,0,0,0,6.9,25.69l.009-.027a1.235,1.235,0,0,0-.411-1.351A10.635,10.635,0,0,1,12.727,5.36V8.011a.324.324,0,0,0,.56.221l3.622-3.871a.324.324,0,0,0,0-.444"
        transform="translate(0 0)"
      />
      <path
        id="Path_21103"
        data-name="Path 21103"
        d="M176.319,102.743a13.094,13.094,0,0,0-5.044-10.05,1.229,1.229,0,0,0-1.925.585l-.008.026a1.236,1.236,0,0,0,.419,1.358,10.635,10.635,0,0,1-6.113,19v-2.541a.324.324,0,0,0-.559-.223l-3.622,3.816a.324.324,0,0,0,0,.444l3.622,3.871a.324.324,0,0,0,.56-.221v-2.665a13.16,13.16,0,0,0,12.672-13.4"
        transform="translate(-150.086 -87.042)"
      />
    </g>
  </svg>
</template>
