import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';

import CustomerInsight from '@views/modules/customer-insight/customer-insight/CustomerInsight.vue';
import CustomerInsightConfig from '@views/modules/customer-insight/customer-insight-config/CustomerInsightConfig.vue';
import CustomerInsightExportPreviewVue from '@/views/modules/customer-insight/customer-insight-export-preview/CustomerInsightExportPreview.vue';
import CustomerInsightManageProps from '@/views/modules/customer-insight/customer-insight-manage-props/CustomerInsightManage.vue';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

const meta = {
  moduleName: moduleName.CustomerInsight,
  moduleVersion: '1.0.0',
};

const customerInsightRoutes: Array<CustomRouteRecord> = [
  {
    path: '/customer-profile',
    redirect: { name: 'customerProfileDashboard' },
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Segment',
        versionKey: segmentVersionKey,
        versionTo: {
          '1.0.0': '/segment/data-segment/main',
          '2.0.0': '/segmentv2',
        },
      },
      {
        text: 'Dashboard',
        active: true,
      },
    ],
  },

  {
    path: '/customer-profile/config/:pageId?',
    name: 'customerProfileConfig',
    component: CustomerInsightConfig,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Segment',
        to: '/segment/data-segment/main',
      },
      {
        text: 'Setting Dashboard',
        active: true,
      },
    ],
  },
  {
    path: '/customer-profile/:cId?/:pageId?',
    name: 'customerProfileDashboard',
    component: CustomerInsight,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Segment',
        to: '/segment/data-segment/main',
      },
      {
        text: 'Customer Insight',
        active: true,
      },
    ],
  },
  {
    path: '/customer-profile/export-preview',
    name: 'export-preview',
    component: CustomerInsightExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
  },
  {
    path: '/customer-profile/manage-property',
    name: 'manage-property',
    component: CustomerInsightManageProps,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Segment',
        to: '/segment/data-segment/main',
      },
      {
        text: 'Setting Dashboard',
        to: '/customer-profile/config/:pageId?',
      },
      {
        text: 'Profile',
        to: '/customer-profile/config/:pageId?',
      },
      {
        text: 'Manage Property',
        active: true,
      },
    ],
  },
];

export default customerInsightRoutes;
