import { ref, Ref, getCurrentInstance, ComponentPublicInstance, computed, reactive } from 'vue';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import apiService from '@/services/api';
import segmentProviderModel from '@/models/segment/segmentProvider';

interface Props {
  readOnly: boolean;
  creditDisplay: number;
  campaign: Campaign.ResponseDetail;
}

export default function useSelectCustomerTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { openDefaultErrorModal } = useValidationModal();
  const { addPersonalizeDataModel } = segmentProviderModel();

  const isSelectedCustomerModalOpen: Ref<boolean> = ref(false);
  const isDrawerOpen: Ref<boolean> = ref(true);
  const isLoading: Ref<boolean> = ref(false);
  const selectedCustomerList: Ref<any[]> = ref([]);
  const isSelectAllCustomer: Ref<boolean> = ref(false);
  const isSelectAllWithExclude: Ref<boolean> = ref(false);
  const searchValue: Ref<string> = ref('');
  const totalRows: Ref<number> = ref(0);
  const excludeList: Ref<any[]> = ref([]);
  const selectedSegment: Ref<Communication.Personalization.PersonalizeSegment> = ref(null!);
  const listCurrent: Ref<Segment.Provider.ListData[]> = ref([]);
  const listAdd: Ref<Segment.Provider.ListData[]> = ref([]);
  const selectedRecipient: Ref<Segment.Provider.SelectedRecipient> = ref(null!);
  const selectedCustomerNumber: Ref<number> = ref(0);
  const isModalPasswordOpen: Ref<boolean> = ref(false);
  const isSelectedCustomerUnConsentModalOpen: Ref<boolean> = ref(false);
  const selectedSegmentInfo = reactive({
    segmentId: null,
    displayName: null,
  });

  const onSelectAll = (isSelectAll: boolean) => {
    isSelectAllCustomer.value = isSelectAll;
  };

  const onSelectChange = (addedList: Record<string, any>[]) => {
    selectedCustomerList.value = addedList;
  };

  const onExcludeListChange = (tableExcludeList: Record<string, any>[]) => {
    excludeList.value = tableExcludeList;
  };

  const onSelectAllWithExclude = (value: boolean) => {
    isSelectAllWithExclude.value = value;
  };

  const onSelectSegment = (segment: Communication.Personalization.PersonalizeSegment) => {
    selectedSegment.value = segment;
  };

  function onOpenSelectedCustomerModal(segmentInfo: any) {
    isSelectedCustomerModalOpen.value = true;
    selectedSegmentInfo.displayName = segmentInfo.display_name;
    selectedSegmentInfo.segmentId = segmentInfo.segment_id;
  }

  function onCloseSelectedCustomerModal() {
    isSelectedCustomerModalOpen.value = false;
  }

  const buildPersonalizeDataPayload = () => {
    addPersonalizeDataModel.payload.Channel = 1;
    addPersonalizeDataModel.payload.list_current = listCurrent.value;
    addPersonalizeDataModel.payload.list_add = resolveListAdd();
  };

  const resolveListAdd = (): Segment.Provider.ListData[] => {
    if (!selectedSegment.value) {
      return listAdd.value;
    }
    if (isSelectAllCustomer.value) {
      listAdd.value.push({
        tableId: selectedSegment.value.table_id,
        select_flag: 2,
        list_data: [],
        filter_search: searchValue.value,
        filtered_record: totalRows.value,
      });
    } else if (isSelectAllWithExclude.value) {
      listAdd.value.push({
        tableId: selectedSegment.value.table_id,
        select_flag: 2,
        list_data: excludeList.value.map((customer) => {
          return { cid: customer.cid };
        }),
        filter_search: searchValue.value,
        filtered_record: totalRows.value,
      });
    } else {
      listAdd.value.push({
        tableId: selectedSegment.value.table_id,
        select_flag: 3,
        list_data: selectedCustomerList.value.map((customer) => {
          return { cid: customer.cid };
        }),
        filter_search: searchValue.value,
        filtered_record: totalRows.value,
      });
    }
    return listAdd.value;
  };

  const onAdd = () => {
    isDrawerOpen.value = true;
    const button: HTMLElement | any = document.querySelector('.add-customer-button');
    const item: HTMLElement | any = document.querySelector('.customer-item');
    const btnOffsetTop: number = button?.offsetTop;
    const btnOffsetRight: number = window.innerWidth - button.offsetLeft;
    const cssPosition = `top: ${btnOffsetTop}px; right: ${btnOffsetRight / 2}px;`;

    const resolveCustomerNumber: number = isSelectAllCustomer.value
      ? totalRows.value
      : isSelectAllWithExclude.value
      ? totalRows.value - JSON.parse(JSON.stringify(excludeList.value)).length
      : JSON.parse(JSON.stringify(selectedCustomerList.value)).length;
    selectedCustomerNumber.value = resolveCustomerNumber;
    item?.classList.remove('hide');
    item?.cloneNode(true);
    item?.setAttribute('style', cssPosition);
    item?.classList.add('send-to-drawer');
    // onAddPersonalizeData();
    setTimeout(function () {
      item?.classList.remove('send-to-drawer');
      item?.classList.add('hide');
    }, 1000);
    if (isSelectAllCustomer.value || selectedCustomerList.value.length || isSelectAllWithExclude) {
      const container = vm?.$refs['select-customer-container'] as HTMLDivElement;
      container.classList.remove('warning');
    }

    vm?.$emit('add-customer', {
      isSelectAll: isSelectAllCustomer.value,
      selectedList: JSON.parse(JSON.stringify(selectedCustomerList.value)),
      excludeList: JSON.parse(JSON.stringify(excludeList.value)),
      isSelectAllWithExclude: isSelectAllWithExclude.value,
      segment: selectedSegment.value,
      totalRows: totalRows.value,
    });

    listAdd.value = [];
    selectedCustomerList.value = [];
    excludeList.value = [];
    isSelectAllCustomer.value = false;
    isSelectAllWithExclude.value = false;
    searchValue.value = '';
  };

  function onRemove(segmentId: number) {
    vm?.$emit('on-remove-segment', segmentId);
  }

  function onChangeFilterSearch(event: any) {
    searchValue.value = event;
    vm?.$emit('on-change-filter-search', searchValue.value, totalRows.value);
  }

  const onTotalRowChange = (totalItems: number) => {
    totalRows.value = totalItems;
  };

  const checkDisabled = computed((): boolean => {
    if ((isSelectAllCustomer.value || selectedCustomerList.value.length || isSelectAllWithExclude.value) && !props.readOnly) {
      return false;
    } else {
      return true;
    }
  });

  const hasSegmentSelected = computed((): boolean => {
    let recpSelect = [];
    if (selectedSegment.value) {
      recpSelect = props.campaign?.recpSelect.filter((recp) => recp.segmentid == selectedSegment.value.segment_id);
    }
    if (recpSelect.length > 0) {
      return true;
    } else {
      return false;
    }
  });

  function onOpenModalPassword() {
    isModalPasswordOpen.value = true;
  }
  function onCloseModalPassword() {
    isModalPasswordOpen.value = false;
  }
  function onOpenSelectedCustomerUnConsentModal() {
    isModalPasswordOpen.value = false;
    isSelectedCustomerUnConsentModalOpen.value = true;
  }
  function onCloseSelectedCustomerUnConsentModal() {
    isSelectedCustomerUnConsentModalOpen.value = false;
  }
  function setSegmentList(segmentInfo: any) {
    selectedSegmentInfo.displayName = segmentInfo.display_name;
    selectedSegmentInfo.segmentId = segmentInfo.segment_id;
  }

  return {
    totalRows,
    isDrawerOpen,
    isLoading,
    selectedCustomerNumber,
    listCurrent,
    selectedRecipient,
    isSelectAllCustomer,
    isSelectAllWithExclude,
    selectedCustomerList,
    excludeList,
    checkDisabled,
    isSelectedCustomerModalOpen,
    selectedSegmentInfo,
    hasSegmentSelected,
    isModalPasswordOpen,
    isSelectedCustomerUnConsentModalOpen,
    onChangeFilterSearch,
    onRemove,
    onSelectAll,
    onSelectChange,
    onAdd,
    onTotalRowChange,
    onExcludeListChange,
    onSelectAllWithExclude,
    onSelectSegment,
    onOpenSelectedCustomerModal,
    onCloseSelectedCustomerModal,
    onOpenModalPassword,
    onCloseModalPassword,
    onOpenSelectedCustomerUnConsentModal,
    onCloseSelectedCustomerUnConsentModal,
    setSegmentList,
  };
}
