import { start } from '@popperjs/core';

export default {
  automations: 'Automations',
  summary: 'Summary',
  status: 'Status',
  all_campaign: 'All Campaign',
  active_campaigne: 'Active',
  pause_campaigne: 'Pause',
  draft_campaigne: 'Draft',
  complete_campaigne: 'Finished',
  status_all: 'All',
  status_draft: 'Draft',
  status_preparing: 'Preparing',
  status_active: 'Active',
  status_archived: 'Archived',
  status_pause: 'Pause',
  status_complete: 'Complete',
  campaign_name: 'Campaign Name',
  start_date: 'Start Date',
  end_date: 'End Date',
  last_update: 'Last Update',
  update_by: 'Update By',
  manage: 'Manage',
  detail: 'Detail',
  clone: 'Clone ',
  edit: 'Edit',
  delete: 'Delete',
  create_campaign: 'Create Campaign',
  date: 'Date',
  clear: 'Clear',
  start_campaign: 'Start Campaign',
  pause_campaign: 'Pause Campaign',
  resume_campaign: 'Resume Campaign',
  saved: 'Saved',
  entry_source: 'Entry Source',
  flow_control: 'Flow Control',
  actions: 'Actions',
  entry_source_segment: 'Segment',
  flow_control_fork_split: 'Fork Split',
  flow_control_distribution_split: 'Distribution Split',
  flow_control_distribution_split_sub: '(Round Robin)',
  flow_control_random_split_true: 'Random Split',
  flow_control_random_split_true_sub: '(True Random)',
  flow_control_random_split_normal: 'Random Split',
  flow_control_random_split_normal_sub: '(Normalize)',
  flow_control_wait_by_duration: 'Wait By Duration',
  flow_control_wait_until_date: 'Wait Until Date',
  flow_control_merge: 'Merge',
  flow_control_limit: 'Limit',
  actions_sms: 'SMS',
  actions_line: 'Line',
  actions_edm: 'EDM',
  modal_use: 'Use',
  modal_next: 'Next',
  modal_back: 'Back',
  modal_cancel: 'Cancel',
  modal_change: 'Change',
  modal_close: 'Close',
  modal_apply: 'Apply',
  modal_view_data: 'View Data',
  modal_folder: 'Folder',
  modal_segment: 'Segment',
  modal_priority: 'Priority',
  modal_year: 'Year',
  modal_month: 'Month',
  modal_day: 'Day',
  modal_hour: 'Hour',
  modal_minute: 'Minute',
  modal_second: 'Second',
  modal_branchs: 'Branchs',
  modal_total_customer_limit: 'Total Customer Limit',
  modal_limit: 'Limit',
  modal_unlimit: 'Unlimit',
  modal_limit_per_customer: 'Limit per Customer',
  modal_mode: 'Mode',
  modal_wait_all: 'Wait All',
  modal_wait_count: 'Wait Count',
  modal_flow_mode: 'Flow Mode',
  modal_non_priority: 'Non Priority',
  modal_entry_priority: 'Entry Priority',
  modal_add: 'Add',
  modal_tooltip_campaign_public: 'Double Click เพื่อดูรายละเอียดเพิ่มเติม',
  modal_tooltip_campaign_draft: 'Double Click เพื่อกรอกรายละเอียดเพิ่มเติม',
  summary_sent: 'Sent',
  summary_condition: 'Condition',
  summary_completed: 'Completed',
  summary_outstanding: 'Outstanding',
  summary_remaining: 'Credit',
  summary_failed: 'Failed',
  summary_last_update: 'Last Update',
  summary_total_customer: 'Total Customer',
  summary_contacts_today: 'Contacts Today',
  summary_ready_to_send: 'Ready to Send',
  summary_unconsented: 'Unconsented',
  summary_success: 'Success',
  summary_campaign_publish: 'Last Campaign Publish',
  summary_total: 'Total',
  summary_persons: 'Persons',
  action_info_title: 'Recipients',
  action_info_campaign_name: 'ชื่อแคมเปญ',
  action_info_recipient: 'ผู้รับ',
  action_info_sender_name: 'ผู้ส่ง',
  action_info_created_by: 'สร้างโดย',
  action_info_last_updated: 'อัปเดตล่าสุด',
  action_info_all_recipient: 'ผู้รับทั้งหมด',
  action_info_success: 'สำเร็จ',
  action_info_fail: 'ล้มเหลว',
  action_info_node_name: 'ชื่อกลุ่มเป้าหมาย',
  action_info_type_desc_source: 'ประเภทกลุ่มเป้าหมาย',
  action_info_type_desc_flow: 'ประเภทเงื่อนไข',
  verify_error: 'Something Wrong',
  verify_error_node: 'Node not Found',
  verify_error_flow: 'Edge not Found',
  add_credit: 'Add Credit',
  journey_paused: 'Journey Paused',
  before_start_journey: 'Before start journey',
  detail_before_start_journey: {
    channel: '',
    start: 'channel, The message will not be delivered to the recipients.',
    end: 'Due to insufficient credit.',
  },
  detail_confirm_before_start_journey: {
    start: 'But you can still send the message by using',
    middle: 'channel',
    end: 'If you would like to start Journey. Please press ',
    confirm: '"confirm"',
  },
  credit_remaining_header: 'Credit Balance',
  channel: 'Channel',
  credit_remaining: 'Credit Balance',
  channel_no_provider: 'Provider not found',
  channel_no_provider_contact: 'Contact Us',
  segment_clone_modal_warning: 'Unable to proceed  because the segment version does not match the current version',
  segment_does_not_match: 'Segment does not match the current version',
};
