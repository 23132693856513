import { CustomRouteRecord } from '@/types/router/router';

import Login from '@/views/modules/authentication/pages/LoginPage.vue';
import VerifyAccount from '@/views/modules/authentication/pages/VerifyAccountPage.vue'

// forget and reset
import ForgetPassword from '@/views/modules/authentication/components/ForgetPassword.vue';
import CheckEmailForget from '@/views/modules/authentication/components/CheckEmailForget.vue';
import ExpireResetPassword from '@/views/modules/authentication/components/ExpireResetPassword.vue';
import LandingPageFromEmail from '@/views/modules/authentication/components/LandingPageFromEmail.vue';

const authenticationRoute: Array<CustomRouteRecord> = [
  {
    path: '/login',
    name: 'auth-login',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/select-brand',
    name: 'select-brand',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset',
    name: 'first-reset-password',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forget',
    name: 'forget-password',
    component: ForgetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/landing-page-email',
    name: 'landing-page-email',
    component: LandingPageFromEmail,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/check-email-forget',
    name: 'check-email-forget',
    component: CheckEmailForget,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/expire-reset-password',
    name: 'expire-reset-password',
    component: ExpireResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    component: VerifyAccount,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
];

export default authenticationRoute;
