export enum ResultListType {
  Result = 1,
  Preview = 2,
}

// TODO clean code
export enum OldSegmentType {
  Batch = 1,
  Realtime = 2,
  Custom = 3,
}

export enum SegmentType {
  Custom = 1,
  Realtime = 2,
  Batch = 3,
}

export enum SegmentStatus {
  Drafting = 0,
  Created = 1,
  Active = 2,
  Caching = 101,
  Error = 500,
  ViewBroken = 501,
  RelationBroken = 502,
}

export enum BuildMode {
  Unknown = 0,
  Selective = 1,
  RulePredicateBuilder = 2,
  RulePredicateTemplate = 3,
  CustomSQL = 4,
  External = 5,
}

export enum ViewMode {
  Basic = 1,
  Advanced = 2,
  Aggregation = 3,
}

export enum SaveType {
  Save = 1,
  DeleteData = 2,
  CopyTo = 3,
}

export enum SortDirection {
  Save = 1,
  DeleteData = 2,
  CopyTo = 3,
}
