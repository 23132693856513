<template>
  <svg
    id="Group_33292"
    data-name="Group 33292"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="21.199"
    height="20.936"
    viewBox="0 0 21.199 20.936"
  >
    <defs>
      <!-- <clipPath id="clip-path-promotion">
        <rect id="Rectangle_14390" data-name="Rectangle 14390" width="21.199" height="20.936" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_33291" data-name="Group 33291" clip-path="url(#clip-path-promotion)"> -->
    <g id="Group_33291" data-name="Group 33291">
      <path
        id="Path_22394"
        data-name="Path 22394"
        d="M19.549,7.307h0A1.911,1.911,0,0,1,18.04,4.694a1.911,1.911,0,0,0-2.787-2.338,1.911,1.911,0,0,1-2.835-1.032,1.911,1.911,0,0,0-3.638,0A1.911,1.911,0,0,1,5.946,2.356,1.911,1.911,0,0,0,3.16,4.694,1.911,1.911,0,0,1,1.651,7.307a1.911,1.911,0,0,0-.631,3.582,1.911,1.911,0,0,1,.524,2.971,1.912,1.912,0,0,0,1.819,3.151A1.911,1.911,0,0,1,5.673,18.95a1.911,1.911,0,0,0,3.418,1.244,1.912,1.912,0,0,1,3.017,0,1.911,1.911,0,0,0,3.418-1.244,1.911,1.911,0,0,1,2.311-1.939,1.911,1.911,0,0,0,1.819-3.151,1.911,1.911,0,0,1,.524-2.971,1.911,1.911,0,0,0-.631-3.582m-.055,2.284a3.38,3.38,0,0,0-.927,5.254.4.4,0,0,1,.081.468.437.437,0,0,1-.384.275.572.572,0,0,1-.118-.013A3.367,3.367,0,0,0,15,16.536,3.349,3.349,0,0,0,14.059,19a.405.405,0,0,1-.106.316.511.511,0,0,1-.355.148.4.4,0,0,1-.33-.175,3.38,3.38,0,0,0-5.335,0,.4.4,0,0,1-.33.175.511.511,0,0,1-.356-.148A.4.4,0,0,1,7.141,19,3.349,3.349,0,0,0,6.2,16.536a3.369,3.369,0,0,0-3.145-.961.572.572,0,0,1-.118.013.434.434,0,0,1-.383-.274.4.4,0,0,1,.08-.468,3.359,3.359,0,0,0,.821-2.854,3.357,3.357,0,0,0-1.748-2.4.442.442,0,0,1,.147-.829A3.357,3.357,0,0,0,4.315,7.1a3.354,3.354,0,0,0,.2-2.962.4.4,0,0,1,.029-.4.49.49,0,0,1,.381-.219.441.441,0,0,1,.235.077,3.377,3.377,0,0,0,5.013-1.825.443.443,0,0,1,.843,0A3.378,3.378,0,0,0,16.035,3.6a.443.443,0,0,1,.235-.077.486.486,0,0,1,.38.219.4.4,0,0,1,.03.4,3.379,3.379,0,0,0,2.667,4.621.442.442,0,0,1,.147.829"
        transform="translate(0 0)"
      />
      <path
        id="Path_22395"
        data-name="Path 22395"
        d="M15.485,8.817,9.5,16.4a.852.852,0,0,0,1.338,1.054l5.981-7.587a.852.852,0,0,0-1.338-1.054"
        transform="translate(-2.477 -2.257)"
      />
      <path
        id="Path_22396"
        data-name="Path 22396"
        d="M10.043,12.409A1.117,1.117,0,1,0,9.1,11.144a1.117,1.117,0,0,0,.945,1.265"
        transform="translate(-2.414 -2.707)"
      />
      <path
        id="Path_22397"
        data-name="Path 22397"
        d="M18.008,16.59a1.117,1.117,0,1,0,.945,1.265,1.116,1.116,0,0,0-.945-1.265"
        transform="translate(-4.446 -4.405)"
      />
    </g>
  </svg>
</template>
