<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.396" height="11.532" viewBox="0 0 15.396 11.532">
    <defs>
      <!-- <clipPath id="clip-path">
        <rect id="Rectangle_318" data-name="Rectangle 318" width="15.396" height="11.532" />
      </clipPath> -->
    </defs>
    <!-- <g id="Group_89" data-name="Group 89" clip-path="url(#clip-path)"> -->
    <g id="Group_89" data-name="Group 89">
      <path
        id="Path_501"
        data-name="Path 501"
        d="M13.829.152a.519.519,0,0,0-.733,0l-7.3,7.3-3.5-3.5a.518.518,0,0,0-.733,0L.152,5.367a.519.519,0,0,0,0,.733l5.28,5.28a.519.519,0,0,0,.733,0L15.245,2.3a.518.518,0,0,0,0-.733Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
